import React, { useState, useRef } from 'react';
import { Draggable } from '@hello-pangea/dnd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { useDataContext } from '../redux/DataContext';

const EnlaceItem = ({links, setLinks, link, index, removeLink }) => {
    const { mediaData, setMediaData, URL_BASE_API  } = useDataContext()

    const [isNew, setIsNew] = useState(link.isNew);
    const [isModified, setIsModified] = useState(false);

    const enlaceRef = useRef()
    const titleRef = useRef()

    function createMedia(mediaData) {
        fetch(`${URL_BASE_API}create-multimedia/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${localStorage.getItem('auth')}`
            },
            body: JSON.stringify(mediaData)
        }).then((response) => response.json())
            .then(data => {

                setMediaData(prevMedias => [...prevMedias, data])

                let elements = [...links]
                let currentElementIndex = elements.findIndex((x) => x.id === link.id);
                if (currentElementIndex === -1) return;
                elements[currentElementIndex] = data 

                setLinks(elements)

                setIsNew(false);
                setIsModified(false);
            }).catch(error => {
            })
    }

    const handleInputTitle = (e) => {
        // setLinkInputValue(e.target.value);
        setIsModified(true);
    };
    const handleInputLink = (e) => {
        // setTitleInputValue(e.target.value);
        setIsModified(true);
    };
    
    const handleUpdateMedia = () => {
        const enlaceValue = enlaceRef.current.value;
        const title = titleRef.current.value;
        const linkData = {
            link_media: enlaceValue,
            title: title,
        };

        updateMedia(link.id, linkData)
            .then(() => {
                setIsModified(false);
            });
    };

    async function updateMedia(linkId, linkData) {
        return fetch(`${URL_BASE_API}update-multimedia/${linkId}/`, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${localStorage.getItem('auth')}`
            },
            body: JSON.stringify(linkData)
        }).then((response) => response.json())
            .then(data => {

                let elements = [...mediaData]
                let currentElementIndex = elements.findIndex((x) => x.id === linkId);
                if (currentElementIndex === -1) return;
                elements[currentElementIndex] = data

                setMediaData(elements)
                setLinks(elements)
            })
            .catch(error => {
                console.error('Error al actualizar enlace:', error);
            });
    }

    
    const handleSaveMedia = () => {
        const link_media = enlaceRef.current.value;
        const title = titleRef.current.value;
    
        const mediaData = {
            title: title,
            link_media: link_media,
            index: index
        };
    
        createMedia(mediaData);
    }

    return (
        <Draggable 
            key={`${link.id}`} 
            draggableId={`${link.id}`} 
            index={index}
            >

            {(provided, snapshot) => (
                <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    className={`bg-gray-200 px-4 py-3 rounded-lg mb-5 ${snapshot.isDragging ? 'dragging' : ''}`}
                >
                    <div className="flex items-center justify-between mb-2">
                        <div className="flex items-center">
                            <FontAwesomeIcon icon={faBars} className=" mr-3  text-slate-600 text-xl" />
                            <p className="text-gray-700 font-bold mr-4">Producto o Servicio #{index + 1}</p>
                        </div>
                        <button
                            className="text-gray-500 hover:bg-gray-100 rounded-xl transition ease-in-out  duration-500 hover:border-white border p-2"
                            onClick={() => removeLink(link.id)}
                        >
                            <p className='text-gray-500 font-medium'>Remover</p>
                        </button>
                    </div>
                    <div className="bg-gray-200 p-2 mt-2">
                        <div className="flex flex-col items-start mb-2">
                            <label className="text-slate-700 mb-2">Título</label>
                            <div className="w-full">
                                <div className="flex relative" >
                                    <input
                                        ref={titleRef}
                                        defaultValue={link.title}
                                        type="text"
                                        className="w-full bg-white px-4 py-2.5 rounded-lg focus:outline-secondary border border-1 border-gray-400"
                                        onChange={(e) => handleInputTitle(e)}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-col items-start mt-2 ">
                            <label className="text-slate-700 mb-2">Enlace</label>
                            <input
                                ref={enlaceRef}
                                type="text"
                                className="w-full bg-white px-4 py-2.5 rounded-lg focus:outline-secondary border border-1 border-gray-400"
                                placeholder="Enlace"
                                defaultValue={link.link_media}
                                onChange={(e) => handleInputLink(e)}
                            />
                        </div>
                    </div>
                    <div className="flex mt-3">
                        <button
                            className={`bg-slate-700 w-full text-white font-bold px-5 text-md py-2 rounded-md ${isNew || isModified ? '' : 'opacity-50 cursor-not-allowed'}`}
                            onClick={isNew ? handleSaveMedia : handleUpdateMedia}
                            disabled={!isNew && !isModified}
                        >
                            {isNew ? `Guardar` : `Actualizar`}
                        </button>
                    </div>
                </div>
            )}
        </Draggable>
    );
};

export default EnlaceItem;
