import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faGear } from '@fortawesome/free-solid-svg-icons';
import { Link, useNavigate } from 'react-router-dom';
import { URL_BASE_API, useDataContext } from '../redux/DataContext.jsx';
import { toast } from 'react-toastify';
import { PremiumModal } from './PremiumModal.jsx';
import { Modal } from './Modal.jsx';
import { GoogleLogout } from '@leecheuk/react-google-login'

const Nav = () => {

  const { profileData } = useDataContext()
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpen2, setIsModalOpen2] = useState(false);

  const navigate = useNavigate();

  const cerrarSesion = () => {
    localStorage.clear();
    navigate('/iniciar-sesion');
  };

  const cancelarPlan = (e) => {
    e.preventDefault();
    fetch(`${URL_BASE_API}cancel-suscription/`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${localStorage.getItem('auth')}`,
      },
    }).then((response) => response.json())
      .then((res) => {
        if (res.type === 'success') {
          localStorage.clear();
          window.location.href = '/iniciar-sesion';
        }
      })
      .catch((error) => { toast.error('Ha ocurrido un error.') });
  };

  return (
    <nav className="bg-gradient-to-r from-primary to-secondary sm:bg-none py-4 lg:mx-10 sm:p-6 sm:bg-transparent transition-all lg:mx-0">
      <Modal
        isOpen={isModalOpen}
        handleClose={() => setIsModalOpen(false)}
        className={'m-5 p-5 w-full md:w-2/3 lg:w-3/6 bg-white rounded-lg shadow-lg'}
      >
        <div className="lg:px-10 pt-6 pb-3 ">
          <PremiumModal isOpen={isModalOpen2} handleClose={() => setIsModalOpen2(false)} />
          <p className="font-black text-slate-700 text-xl text-center mb-5">Configuración</p>
          <div className="bg-zinc-100 border shadow px-2 py-3 flex flex-col gap-3 rounded">
            <p className="font-bold text-slate-700">Correo Electrónico</p>
            <div className="border-t border-2 border-zinc-400 rounded"></div>
            <p className="break-words">{profileData && profileData.profile_email}</p>
          </div>
          <div className="mt-5 bg-zinc-100 border shadow px-2 py-3 flex flex-col gap-3 rounded">
            <p className="font-bold text-slate-700">Tipo de Plan</p>
            <div className="border-t border-2 border-zinc-400 rounded"></div>
            <div className="flex justify-between items-center">
              <p>{profileData && profileData.plan === 1 ? 'Básico' : 'Premium'}</p>
              {profileData && profileData.plan === 1 && (
                <button
                  onClick={() => setIsModalOpen2(true)}
                  className="text-white bg-gradient-to-br from-amber-500 to-orange-400 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-amber-500  font-medium rounded-lg px-5 py-3 text-center"
                >
                  Volverse Premium
                </button>
              )}
            </div>
          </div>
          <div className="flex justify-between items-center mt-5">

            <button
              onClick={cerrarSesion}
              type="button"
              className="p-2 border border-2 border-pink-800 rounded hover:underline cursor-pointer block font-semibold text-pink-800"
            >
              Cerrar Sesión
            </button>
            {profileData && profileData.plan === 2 && (
              <button onClick={cancelarPlan} className="p-3 rounded font-bold text-white">
                Cancelar plan
              </button>
            )}
          </div>
        </div>
      </Modal>
      <div className="bg-transparent sm:shadow-sm sm:bg-gradient-to-r from-primary to-secondary px-5 flex items-center justify-between sm:px-6 sm:py-4 rounded-xl">
        <div className="p-[5px] md:p-0">
          <h1 className="font-semibold text-3xl" aria-label='Kdo Logo' alt='KDO'>
            <svg width="85" height="45" viewBox="0 0 601 279" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fillRule="evenodd" clipRule="evenodd" d="M83.5 94.5V12.5C83 9 80.8 2 76 2C71.2 2 29.3333 0.666667 9 0C7.16667 0 3.5 1.4 3.5 7C3.5 12.6 1.16667 124 0 179V271C1.16667 273.5 4.7 278.2 9.5 277C14.3 275.8 21.5 269.833 24.5 267L61 230.5C62.3333 229.833 64.8 230 64 236C63.2 242 63.6667 261.833 64 271C64.8333 274.167 68.5 279.8 76.5 277C93.6667 260.333 128.6 226.4 131 224C131.341 223.659 131.663 223.305 131.977 222.959C134.425 220.265 136.41 218.081 143.5 226.5C149.9 234.1 176.833 261 189.5 273.5C191.167 275.667 197.2 279.7 208 278.5C218.8 277.3 248.5 278 262 278.5C263.833 278.5 267.9 278.2 269.5 277L269.577 276.942C271.671 275.375 278.351 270.375 266.5 260.5C256.9 252.5 206.833 200.167 183 175C182.5 174.5 182.8 172.4 188 168L242 114.5L272 84.5C273.5 81.6667 275.1 75.3 269.5 72.5C262.848 69.1739 262.517 69.4602 253.061 77.6498L253.059 77.6508C252.565 78.0788 252.046 78.5283 251.5 79C242.7 86.6 180.167 148.5 150 178.5L86 243C84.5 244 81.5 245.4 81.5 243V211L273 24.5C275 19.6667 276.6 9.8 267 9C257.4 8.2 203.333 6.66667 177.5 6C176.333 5.66667 173.1 6.1 169.5 10.5C165.9 14.9 114.667 65.6667 89.5 90.5C87.1667 93.1667 82.7 97.7 83.5 94.5ZM21 155.5V18.5L64 21L65 115L21 155.5ZM20.5 243.5V180.5L182 28H241.5L20.5 243.5ZM171 187L150.5 205.5L203 260.5H242L171 187Z" fill="white" />
              <path fillRule="evenodd" clipRule="evenodd" d="M305 133C302.239 133 300 135.239 300 138V271C300 273.761 302.239 276 305 276H370C402 276 434.5 251 436 202C436 183.5 426 156 395.5 139C387.513 134.548 367.996 133.135 366.142 133.009C366.038 133.002 365.948 133 365.843 133H336H305ZM342.5 167.5C339.739 167.5 337.5 169.739 337.5 172.5V235.5C337.5 238.261 339.739 240.5 342.5 240.5H368.897C383.25 240.5 397.827 227.738 398.5 202.724C398.5 193.28 394.015 179.241 380.335 170.563C376.871 168.365 368.571 167.618 367.275 167.513C367.156 167.504 367.044 167.5 366.925 167.5H353.647H342.5Z" fill="white" />
              <path fillRule="evenodd" clipRule="evenodd" d="M526.5 279C567.645 279 601 245.645 601 204.5C601 163.355 567.645 130 526.5 130C485.355 130 452 163.355 452 204.5C452 245.645 485.355 279 526.5 279ZM526 242C546.435 242 563 225.435 563 205C563 184.565 546.435 168 526 168C505.565 168 489 184.565 489 205C489 225.435 505.565 242 526 242Z" fill="white" />
            </svg>
          </h1>
        </div>
        <div className='flex gap-3'>
          <div onClick={() => setIsModalOpen(true)} className=" box-border border-white hover:bg-orange-400 transition ease-in-out duration-300 border-secondary border-2 rounded-lg px-4 py-[10px] cursor-pointer hover:bg-orange-100">
            <FontAwesomeIcon icon={faGear} className="md:hidden fa-regular fa-image text-xl text-white" />
            <span className="hidden md:flex text-white font-bold">Configuración</span>
          </div>
          <Link
            to={profileData && profileData.profile_url + '/preview'}
            className="box-border bg-white hover:bg-orange-100 transition ease-in-out duration-300 border-secondary border-2 rounded-lg px-4 py-[10px] cursor-pointer hover:bg-orange-100">
            <div className="md:hidden">
              <FontAwesomeIcon icon={faEye} className="fa-regular fa-image text-xl text-secondary" />
            </div>
            <span className="hidden md:flex text-secondary font-bold">Ver Tarjeta</span>
          </Link>
        </div>
      </div>
    </nav>
  );
};

export default Nav;