import { BrowserRouter as Router, Navigate, Route, Routes, useParams } from 'react-router-dom'
import App from './App';
import Login from './pages/Login';
import Preview from './pages/Preview';

import { useEffect, useState } from 'react';
import DataProvider, { URL_BASE_API } from './redux/DataContext'
import NotFound from './pages/NotFound';
import Reestablecer from './pages/Reestablecer';
import Recuperar from './pages/Recuperar';
import Register from './pages/Register';
import Verify from './pages/Verify';

function Routing() {

    const Protected = ({ children }) => {
        const [isLogged, setIsLogged] = useState(false);
        const [isLoading, setIsLoading] = useState(true);
        useEffect(() => {
            const auth = localStorage.getItem('auth');
            if (auth) {
                fetch(`${URL_BASE_API}token/${auth}/`, {
                    method: 'GET'
                }).then(response => response.json())
                    .then(data => {
                        if (data.verified) {
                            setIsLogged(true);
                        } else {
                            setIsLogged(false)
                        }
                    })
                    .catch(error => {
                        setIsLogged(false)
                        // localStorage.clear()
                    }).finally(() => {
                        setIsLoading(false);
                    });
            } else {
                setIsLoading(false);
            }
        }, []);
        if (isLoading) {
            return null;
        }
        if (isLogged) {
            return children;
        } else {
            return <Navigate to="/iniciar-sesion" />
        }
    }; 

    function UserData() {
        const { user_url } = useParams();
        const [loadingMeta, setLoadingMeta] = useState(true);
        const [userData, setUserData] = useState(null);
    
        useEffect(() => {
            fetch(`${URL_BASE_API}get-user-card-data/${user_url}/`, {
                method: 'GET'
            })
            .then(async data => {
                if (data.status == 200) {
                    let response = await data.json()
                    setUserData(response);  
                    setLoadingMeta(false);
                } else {
                    window.location.href = '/not-found'
                }
            })
            .catch(error => {
                window.location.href = '/not-found'
            })
        }, [user_url]);
    
        // Verificar si los datos están cargados antes de renderizar <Preview />
        if (loadingMeta || !userData) {
            return null; // Puedes mostrar un spinner de carga o cualquier otra cosa mientras se carga
        }
    
        return (
            <>
                <Preview userData={userData} />
            </>
        );
    }

    return (
        <Router>
            <Routes>
                <Route exact path="/" element={
                    <Protected>
                        <DataProvider>
                            <App />
                        </DataProvider>
                    </Protected>
                } />
                <Route exact path="/iniciar-sesion" element={
                    <Login />
                } />
                  <Route exact path="/registrarse" element={
                    <Register />
                } />
                <Route exact path="/recuperar-clave" element={
                    <Recuperar />
                } />
                <Route exact path="/reestablecer-clave/:token" element={
                    <Reestablecer />
                } />
                <Route exact path="/verificado/:token" element={
                    <Verify />
                } />
                <Route path="/:user_url" element={
                    <UserData />                   
                } />
                <Route path="/:user_url/:is_preview" element={
                    <UserData />
                } />
                <Route exact path="/not-found" element={<NotFound />} />
            </Routes>
        </Router>
    );
}

export default Routing;