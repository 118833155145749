import { createPortal } from 'react-dom';
import { useEffect, useState, useCallback } from 'react';
import { m, LazyMotion } from 'framer-motion';

const effect = {
    hidden: {
        scale: 0,
        opacity: 0,
    },
    visible: {
        scale: 1,
        opacity: 1,
        transition: {
            type: 'spring',
            stiffness: 600,
            damping: 30,
        },
    },
    exit: {
        scale: 0,
        opacity: 0,
    },
};
const loadFeatures = () =>
    import("../features.js").then(res => res.default)
const Backdrop = ({ children, handleClose }) => (
    <m.div
        className="
        z-50 fixed inset-0
        flex items-center justify-center
        bg-backdrop backdrop-filter backdrop-blur-[2px]
        backdrop-brightness-75
    "
        onClick={handleClose}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
    >
        {children}
    </m.div>
);

const ModalContent = ({ className, children, handleClose, ariaLabel,disableAnimations }) => (
    <m.div
        tabIndex={-1}
        role="dialog"
        aria-modal={true}
        aria-label={ariaLabel}
        className={`relative ${className}`}
        variants={disableAnimations ? {} : effect}
        initial="hidden"
        animate="visible"
        exit="exit"
        onClick={(event) => event.stopPropagation()}
    >
        {children}
        {handleClose && (
            <button
                className="absolute top-0 right-0 p-2"
                onClick={handleClose}
                aria-label={`Cerrar ${ariaLabel || 'dialogo'}`}
            >
                <svg className="w-6 h-6" fill="currentColor" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
                    <path d="M289.94 256l95-95A24 24 0 00351 127l-95 95-95-95a24 24 0 00-34 34l95 95-95 95a24 24 0 1034 34l95-95 95 95a24 24 0 0034-34z" />
                </svg>
            </button>
        )}
    </m.div>
);

export const Modal = ({
    children,
    className,
    isOpen,
    handleClose,
    hideCloseButton,
    backdropDismiss = true,
    ariaLabel,
    disableAnimations = false
}) => {
    const [isBrowser, setIsBrowser] = useState(false);

    const handleKeyDown = useCallback(
        (event) => {
            if (!isOpen || event.key !== 'Escape') return;
            handleClose();
        },
        [isOpen, handleClose]
    );

    useEffect(() => {
        if (!isOpen) return;

        document.body.style.overflow = 'hidden';
        document.addEventListener('keydown', handleKeyDown);

        return () => {
            document.body.style.overflow = 'auto';
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [isOpen, handleKeyDown]);
    
    useEffect(() => {
        setIsBrowser(true);
    }, []);

    if (!isBrowser) return <></>;

    return createPortal(
        <LazyMotion features={loadFeatures}>
            <m.div initial={false} mode='wait'>
                {isOpen && (
                    <Backdrop handleClose={backdropDismiss ? handleClose : undefined}>
                        <ModalContent
                            className={className}
                            handleClose={hideCloseButton ? undefined : handleClose}
                            ariaLabel={ariaLabel}
                            disableAnimations={disableAnimations}
                        >
                            {children}
                        </ModalContent>
                    </Backdrop>
                )}
            </m.div>
        </LazyMotion>,
        document.getElementById('modal-portal')
    );
};
