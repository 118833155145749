import React, { useRef, useState } from "react";
import { LazyMotion, m } from "framer-motion";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { LogoAnimated } from "../AnimatedLogo";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { URL_BASE_API } from "../redux/DataContext";

const Login = () => {
  const emailRef = useRef();
  const passwordRef = useRef();
  const navigate = useNavigate();

  const loadFeatures = () =>
    import("../features.js").then((res) => res.default);

  const [showPassword, setShowPassword] = useState(false);
  const togglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const LoginUser = (e) => {
    e.preventDefault();

    if (!validateFields()) {
      return;
    }
    const email = emailRef.current.value.trim();
    const password = passwordRef.current.value;

    const userData = {
      email: email,
      password: password,
    };

    fetch(`${URL_BASE_API}signin/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(userData),
    })
      .then(async (res) => {
        if (res.status === 200) {
          const data = await res.json();
          localStorage.setItem("auth", data.token);
          navigate("/");
        } else if (res.status === 404) {
          toast.error("Usuario no encontrado.");
        } else if (res.status === 403) {
          toast.error("Debes activar tu cuenta.");
        } else if (res.status === 400) {
          toast.error(
            "Contraseña incorrecta. Por favor, verifica tu contraseña."
          );
        }
      })
      .catch((error) => {
        toast.error("Hubo un error al iniciar sesión");
      });
  };

  const validateFields = () => {
    const email = emailRef.current.value.trim();
    const password = passwordRef.current.value;

    if (!email || !password) {
      toast.error("Por favor, completa todos los campos.");
      return false;
    }
    return true;
  };

  return (
    <div
      className="bg-gradient-to-r from-primary to-amber-500"
    >
      <ToastContainer />
      <section className="min-h-screen">
        <div className="px-5 bg-white sm:bg-transparent flex flex-col items-center justify-center md:px-6 py-8 mx-auto h-screen lg:py-0">
          <div className="hidden sm:flex -mt-10">
            <LogoAnimated isWhite={true} />
          </div>
          <div className="sm:hidden -mt-10">
            <LogoAnimated />
          </div>
          <LazyMotion features={loadFeatures}>
            <m.div
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.6, delay: 0.3 }}
              className="w-full bg-white rounded-lg sm:shadow sm:border md:mt-0 sm:max-w-md xl:p-0"
            >
              <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
                <h1 className="text-2xl text-center font-bold leading-tight tracking-tight text-gray-900 md:text-3xl text-slate-700">
                  Inicia Sesión
                </h1>
                <form className="space-y-4 md:space-y-6 " action="#">
                  <div className="relative">
                    <label
                      htmlFor="email"
                      className="block mb-2 text-sm font-medium text-slate-800"
                    >
                      Email
                    </label>
                    <input
                      ref={emailRef}
                      type="email"
                      name="email"
                      id="email"
                      placeholder="correo@email.com"
                      className="bg-slate-200 text-gray-900 sm:text-sm rounded-lg block w-full p-2.5 placeholder-gray-400 text-slate-700 shadow-sm focus:outline-none"
                    />
                  </div>
                  <div className="relative">
                    <label
                      htmlFor="password"
                      className="block mb-2 text-sm font-medium text-slate-800"
                    >
                      Contraseña
                    </label>
                    <div className="flex items-center">
                      <input
                        ref={passwordRef}
                        type={showPassword ? "text" : "password"}
                        name="password"
                        id="password"
                        maxLength="200"
                        className="bg-slate-200 text-gray-900 sm:text-sm rounded-lg block w-full p-2.5 placeholder-gray-400 text-slate-700 shadow-sm focus:outline-none"
                        required
                      />
                      <button
                        aria-label="Mostrar contraseña"
                        type="button"
                        className="text-gray-400 hover:text-gray-600 focus:outline-none ml-5"
                        onClick={togglePasswordVisibility}
                      >
                        <FontAwesomeIcon
                          icon={showPassword ? faEye : faEyeSlash}
                        />
                      </button>
                    </div>
                  </div>
                  <div className="flex items-center justify-between">
                    <Link to="/recuperar-clave">
                      <p className="py-2 text-sm font-medium text-slate-500 hover:underline">
                        Recuperar Contraseña
                      </p>
                    </Link>
                  </div>
                  <button
                    onClick={LoginUser}
                    type="submit"
                    className="w-full  bg-slate-700 hover:bg-primary-700 focus:ring-4 text-[19px] text-white focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center "
                  >
                    Iniciar Sesión
                  </button>
                  <Link to="/registrarse">
                    <p className="text-sm font-light text-gray-500 pt-6 ">
                      No tienes una cuenta aún?{" "}
                      <span className="font-medium text-slate-700 hover:underline">
                        Regístrate
                      </span>
                    </p>
                  </Link>
                </form>
              </div>
            </m.div>
            <div className="mt-5">
              <a
                href="https://gealytics.cl"
                target="_blank"
                className="text-left w-full mt-10 text-slate-500 sm:text-gray-100 text-xs cursor-pointer"
              >
                Powered by <span className="font-bold">Gealytics</span>
              </a>
            </div>
          </LazyMotion>
        </div>
      </section>
    </div>
  );
};

export default Login;
