import React, { useEffect, useState, useRef } from 'react';
import imagenPerfil from '../imagen.svg';
import AvatarEditor from 'react-avatar-editor';
import { toast } from 'react-toastify';
import { Modal } from './Modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faImage } from '@fortawesome/free-solid-svg-icons';
const ImageEditor = ({ defaultImage, setProfileImage, setProfileImageChanged, setIsModified }) => {
  const avatarEditorRef = useRef(null);

  const [dialogs, setDialogs] = useState(false);
  const [imgCrop, setImgCrop] = useState(null);
  const [imagenFondo, setImagenFondo] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);


  useEffect(() => {
    if (defaultImage) {
      setImgCrop(defaultImage);
      setImagenFondo(defaultImage)
    }
  }, [defaultImage]);

  const handleFileInputChange = (e) => {
    const file = e.target.files[0];

    if (file) {
      if (!isValidImageFile(file.name)) {
        toast.error('La imagen debe tener una extensión válida: png, jpg o jpeg.');

      } else {
        setSelectedFile(file);
        const imageUrl = URL.createObjectURL(file);
        const img = new Image();
        img.src = imageUrl;
        img.onload = () => {
          const resizedImage = resizeImage(img, 500, 500);
          setImgCrop(resizedImage);
        }
      }
    }
  };


  const saveImage = () => {
    if (avatarEditorRef.current && selectedFile) {
      if (!isValidImageFile(selectedFile.name)) {
        toast.error('La imagen debe tener una extensión válida: png, jpg o jpeg.', {
          position: 'top-right',
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setDialogs(false);
        setImagenFondo(defaultImage)
        setDialogs(false);
        return;
      }

      const canvas = avatarEditorRef.current.getImage();
      const base64String = canvas.toDataURL();

      setProfileImage(base64String);
      setProfileImageChanged(true);
      setDialogs(false);
      setSelectedFile(null);
      setIsModified(true);
    } else {
      setDialogs(false);
    }
  };

  function isValidImageFile(fileName) {
    const validExtensions = ['png', 'jpg', 'jpeg'];
    const extension = fileName.split('.').pop().toLowerCase();
    return validExtensions.includes(extension);
  }

  const deleteImage = () => {
    setIsModified(true);
    setProfileImageChanged(true);
    setImgCrop('');
    setProfileImage('');
    setImagenFondo('')
  };

  function resizeImage(image, maxWidth, maxHeight) {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    let width = image.width;
    let height = image.height;

    if (width > height) {
      if (width > maxWidth) {
        height *= maxWidth / width;
        width = maxWidth;
      }
    } else {
      if (height > maxHeight) {
        width *= maxHeight / height;
        height = maxHeight;
      }
    }

    canvas.width = width;
    canvas.height = height;

    ctx.drawImage(image, 0, 0, width, height);

    return canvas.toDataURL('image/jpeg'); // Cambia a 'image/png' si deseas un formato diferente
  }



  return (
    <div className="">
      <div className="text-center p-4 ">
        <div className="div border border-[3px] border-slate-500" style={{
          width: '180px',
          height: '180px',
          borderRadius: '50%',
          overflow: 'hidden',
        }}>
          <img
            style={{
              width: '100%',
              height: '100%',
              objectFit: 'cover',
            }}
            src={imagenFondo || imagenPerfil}
            onClick={() => setDialogs(true)}
            alt="Profile"
          />
          <Modal className={'m-5 p-5 bg-white rounded-lg shadow-lg'} isOpen={dialogs} handleClose={() => setDialogs(false)}>
            <div className="p-2 confirmation-content flex flex-col align-items-center">
              <input
                type="file"
                accept=".png, .jpg, .jpeg"
                onChange={handleFileInputChange}
                style={{ display: 'none' }}
                id="fileInput"
              />
              {imgCrop && (<p className='text-zinc-500 text-sm -mt-4 py-2 font-bold mb-5'>Puedes arrastrar la imagen para ajustarla.</p>
              )}
              {imgCrop && (
                <AvatarEditor
                  ref={avatarEditorRef}
                  image={imgCrop}
                  width={300}
                  height={300}
                  border={0}
                  borderRadius={150}
                  color={[0.0, 0.0, 0.0, 0.6]}
                  scale={1.0}
                  rotate={0}
                />
              )}
              {!imgCrop && (<div className=' mb-4 text-center flex flex-col sm:flex-row gap-10 mt-5 text-slate-700'>
                <label htmlFor="fileInput" role='button' className='py-10 bg-slate-200 p-5 gap-3 rounded border border-2 flex flex-col justify-center border-slate-400'>
                  <FontAwesomeIcon icon={faImage} className='text-3xl' />
                  <p className='font-semibold'>Seleccionar de mi PC</p>
                </label>
              </div>)}
              <div className="flex mt-4 rounded-md  text-center w-full gap-4">
                {imgCrop && (
                  <button
                    aria-label='Borrar imagen'
                    onClick={deleteImage}
                    className='w-full text-white text-xl bg-red-500 font-semibold rounded'
                  >
                    Eliminar
                  </button>
                )}
                <button
                  aria-label='Guardar imagen banner'
                  onClick={saveImage}
                  className='w-full text-white text-xl font-semibold bg-slate-600 rounded py-2'
                >
                  Guardar
                </button>
              </div>
            </div>
          </Modal>
        </div>
      </div>
    </div>
  );
};

export default ImageEditor;