import React, { useState, useRef, useEffect } from 'react'
import { useDataContext } from '../redux/DataContext.jsx'
import ImageEditor from '../components/ImagePickerEditor.jsx';
import { toast } from 'react-toastify';
import BannerEditor from '../components/BannerPickerEditor.jsx';

const Perfil = () => {
  const { profileData, setProfileData, divHeight, setDivHeight, URL_BASE_API, URL_BASE } = useDataContext()

  const [backgroundColor, setBackgroundColor] = useState('#E2E8F0');
  const [textColor, setTextColor] = useState('#334155');
  const nombreRef = useRef(null);
  const correoRef = useRef(null);
  const descripcionRef = useRef(null);
  const direccionRef = useRef(null);

  const [profileImage, setProfileImage] = useState(null);
  const [profileImageChanged, setProfileImageChanged] = useState(false)

  const [profileBanner, setProfileBanner] = useState(null);
  const [profileBannerChanged, setProfileBannerChanged] = useState(false)

  const [isNew, setIsNew] = useState(true);
  const [isModified, setIsModified] = useState(false);

  const handleColorChange = (event) => {
    setBackgroundColor(event.target.value);
    setIsModified(true);
    profileData.profile_color = event.target.value
  };
  const handleTextColor = (event) => {
    setTextColor(event.target.value);
    setIsModified(true);
    profileData.profile_text_color = event.target.value
  };

  useEffect(() => {
    const targetDiv = document.querySelector('#perfilDiv');
    if (targetDiv) {
      setDivHeight(targetDiv.offsetHeight);
    }
  }, [divHeight]);

  useEffect(() => {
    if (profileData) {
      setIsNew(false);
      nombreRef.current.value = profileData.profile_name;
      correoRef.current.value = profileData.profile_email;
      descripcionRef.current.value = profileData.profile_description;
      direccionRef.current.value = profileData.profile_address ||''
      setBackgroundColor(profileData.profile_color);
      setTextColor(profileData.text_color)
      if (profileData.profile_image) {
        setProfileImage(`${URL_BASE}${profileData.profile_image}`);
      }
      if (profileData.profile_banner) {
        setProfileBanner(`${URL_BASE}${profileData.profile_banner}`);
      }
    }
  }, [profileData]);

  const handleInputChange = () => {
    setIsModified(true);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const nombreValue = nombreRef.current.value;
    const correoValue = correoRef.current.value;
    const descripcionValue = descripcionRef.current.value;
    const direccionValue = direccionRef.current.value;
    setIsNew(false);
    setIsModified(false);

    const formData = new FormData();

    const colorSeleccionado = backgroundColor === ' ' ? "#000000" : backgroundColor;
    const colorTexto = textColor === ' ' ? "#000000" : textColor;

    formData.append('profile_color', colorSeleccionado);
    formData.append('text_color', colorTexto);
    formData.append('profile_email', correoValue);
    formData.append('profile_name', nombreValue);
    formData.append('profile_description', descripcionValue);
    formData.append('profile_address', direccionValue)

    if (profileImageChanged) {
      formData.append('profile_image', profileImage)
      setProfileImageChanged(false)
    }

    if (profileBannerChanged) {
      formData.append('profile_banner', profileBanner)
      setProfileBannerChanged(false)
    }

    console.log(profileBanner)
    console.log(profileImage)

    fetch(`${URL_BASE_API}set-data-card/`, {
      method: 'POST',
      headers: {
        'Authorization': `Token ${localStorage.getItem('auth')}`
      },
      body: formData
    }).then(response => response.json())
      .then(data => {
        setProfileData(data)
      })
      .catch(error => {
        toast.error('Ha ocurrido un error al guardar la información.')
      })
  };

  return (
    <div id='perfilDiv' className="w-full sm:px-6 md:px-0 sm:mx-auto sm:mb lg:px-0">
      <div className="flex flex-col bg-white p-6 sm:rounded-b-xl text-center text-gray-500">
        <div className="text-left">
          <h1 className="text-3xl font-bold mb-2 flex justify-start text-slate-700">Detalles de tu Perfil</h1>
          <p className="text-lg mb-4 flex justify-start text-slate-500">Agregue sus datos para crear un toque personal en su perfil.
          </p>
          <form method="post" onSubmit={handleSubmit} encType="multipart/form-data">
            <div className='flex gap-5'>
              <div className="flex flex-row my-4 items-center">
                <div
                  className='rounded-md border border-2 border-gray-300'
                  style={{ backgroundColor: backgroundColor }}
                >
                  <input
                    type="color"
                    value={backgroundColor}
                    onChange={handleColorChange}
                    className="w-10 h-10 bg-select-color cursor-pointer appearance-none rounded-md"
                    style={{ padding: 0, boxShadow: 'none', outline: 'none' }}
                  />
                </div>
                <label className="ml-3 text-md text-slate-600">Selecciona un color de fondo</label>
              </div>
              <div className="flex flex-row my-4 items-center">
                <div
                  className='rounded-md border border-2 border-gray-300'
                  style={{ backgroundColor: textColor }}
                >
                  <input
                    type="color"
                    value={textColor}
                    onChange={handleTextColor}
                    className="w-10 h-10 bg-select-color cursor-pointer appearance-none rounded-md"
                    style={{ padding: 0, boxShadow: 'none', outline: 'none' }}
                  />
                </div>
                <label className="ml-3 text-md text-slate-600">Selecciona el color del texto</label>
              </div>
            </div>
            <div className=" flex flex-col lg:flex-row md:gap">
              <ImageEditor defaultImage={profileImage} setProfileImage={setProfileImage} setProfileImageChanged={setProfileImageChanged} setIsModified={setIsModified} />
              <BannerEditor defaultImage={profileBanner} setBannerImage={setProfileBanner} setBannerImageChanged={setProfileBannerChanged} setIsModified={setIsModified} />
            </div>
            <div className="mt-5">
              <input type="text" placeholder="Nombre" ref={nombreRef} onChange={handleInputChange}
                className="rounded-lg border border-2 border-gray-300 p-2 mb-2 w-full text-md" required />
              <input type="email" placeholder="Correo" ref={correoRef} onChange={handleInputChange}
                className="rounded-lg border border-2 border-gray-300 p-2 mb-2 w-full text-md" required />
              <input type="text" placeholder="Dirección (Opcional)" ref={direccionRef} onChange={handleInputChange}
                className="rounded-lg border border-2 border-gray-300 p-2 mb-2 w-full text-md" />
              <textarea maxLength="300" placeholder="Descripción (opcional)" ref={descripcionRef} onChange={handleInputChange}
                className="rounded-lg border border-2 border-gray-300 p-2 mb-2 w-full resize-y text-md"></textarea>
            </div>
            <div className="flex justify-between">
              <div className="flex sm:justify-end w-full">
                {/* <button type="submit" className="w-full sm:w-auto sm:px-10 mt-1 lg:mt-3 bg-primary text-white px-4 py-2 rounded text-xl font-semibold">
                  Guardar
                </button> */}
                <button
                  className={`w-full sm:w-auto sm:px-10 mt-1 lg:mt-3 bg-primary text-white px-4 py-2 rounded text-xl font-semibold ${isNew || isModified ? '' : 'opacity-50 cursor-not-allowed'}`}
                  // onClick={isNew ? handleSaveLink : handleUpdateLink}
                  disabled={!isNew && !isModified}
                >
                  {isNew ? `Guardar` : `Actualizar`}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default Perfil