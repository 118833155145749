import React, { useEffect, useState, useRef } from 'react';
import imagenPerfil from '../banner.svg';
import AvatarEditor from 'react-avatar-editor';
import { toast } from 'react-toastify';
import { Modal } from './Modal';
import { useDataContext } from '../redux/DataContext';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faImage, faImages } from '@fortawesome/free-solid-svg-icons';

const BannerEditor = ({ defaultImage, setBannerImage, setBannerImageChanged, setIsModified }) => {
    const bannerEditorRef = useRef(null);
    const [dialogs, setDialogs] = useState(false);
    const [imgCrop, setImgCrop] = useState(null);
    const [imagenFondo, setImagenFondo] = useState(null);
    const [selectedFile, setSelectedFile] = useState(null);
    const [modalOpen, setModalOpen] = useState(false)
    const [selectedGalleryImage, setSelectedGalleryImage] = useState(null);

    const {
        URL_BASE
    } = useDataContext();

    // const imageUrls = [
    //     require('../images/galery/banner1.jpg'),
    //     require('../images/galery/banner2.jpg'),
    //     require('../images/galery/banner3.jpg'),
    //     require('../images/galery/banner4.jpg'),
    // ];


    useEffect(() => {
        if (defaultImage) {
            setImgCrop(defaultImage);
            setImagenFondo(defaultImage)
        }
    }, [defaultImage]);

    // const handleFileInputChange = (e) => {
    //     const file = e.target.files[0];
    //     if (file) {
    //         if (!isValidImageFile(file.name)) {
    //             toast.error('La imagen debe tener una extensión válida: png, jpg o jpeg.');
    //         } else {
    //             setSelectedFile(file);
    //             const imageUrl = URL.createObjectURL(file);
    //             setImgCrop(imageUrl);
    //         }
    //     }
    // };

    const handleFileInputChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            if (!isValidImageFile(file.name)) {
                toast.error('La imagen debe tener una extensión válida: png, jpg o jpeg.');
            } else {
                setSelectedFile(file);
                const imageUrl = URL.createObjectURL(file);
                const img = new Image();
                img.src = imageUrl;
                img.onload = () => {
                    const resizedImage = resizeImage(img, 1400, 500);
                    setImgCrop(resizedImage);
                }
            }
        }
    };
    // const resizedImage = resizeImage(img, 600, 300); // Cambia los valores según tus necesidades


    const deleteImage = () => {
        setIsModified(true);
        setBannerImageChanged(true);
        setImgCrop('');
        setImagenFondo('');
        setBannerImage('');
    };
    // const handlePredefinedImageClick = (image) => {
    //     setImgCrop(image); // Mostrar la imagen seleccionada desde la galería en el editor
    //     setSelectedGalleryImage(image); // Guardar la imagen seleccionada desde la galería en el estado
    //     setDialogs(true); // Abrir el modal del editor
    // };

    function isValidImageFile(fileName) {
        const validExtensions = ['png', 'jpg', 'jpeg'];
        const extension = fileName.split('.').pop().toLowerCase();
        return validExtensions.includes(extension);
    }

    const saveImage = async () => {
        const imageToSave = selectedGalleryImage || selectedFile; // Utilizar la imagen seleccionada desde la galería o el PC
        if (selectedGalleryImage) {
            // Descargar la imagen desde la URL en selectedGalleryImage
            const imageUrl = selectedGalleryImage;

            // Descargar la imagen como base64
            const response = await fetch(imageUrl);

            if (response.ok) {
                const blob = await response.blob();
                const reader = new FileReader();

                reader.onloadend = () => {
                    // Aquí tienes el base64String en el formato deseado
                    const base64String = `data:${blob.type};base64,${reader.result}`;

                    // Ejecutar la lógica de guardado
                    setBannerImage(base64String);
                    setBannerImageChanged(true);
                    setDialogs(false);
                    setSelectedFile(null);
                    setSelectedGalleryImage(null); // Limpiar la imagen seleccionada desde la galería
                    setIsModified(true);
                };

                reader.readAsDataURL(blob);
            } else {
                toast.error('Error al descargar la imagen desde la URL seleccionada.');
            }
        } else if (bannerEditorRef.current && imageToSave) {
            if (!isValidImageFile(imageToSave.name)) {
                toast.error('La imagen debe tener una extensión válida: png, jpg o jpeg.');
                setDialogs(false);
                setImagenFondo(defaultImage);
                return;
            }
            console.log(bannerEditorRef.current.getImage()); // Aquí, la imagen es de 6000x2000 pero el canvas debo bajarle esas dimensioens
            const canvas = bannerEditorRef.current.getImage();
            const base64String = canvas.toDataURL();

            setBannerImage(base64String);
            setBannerImageChanged(true);
            setDialogs(false);
            setSelectedFile(null);
            setSelectedGalleryImage(null); // Limpiar la imagen seleccionada desde la galería
            setIsModified(true);
        } else {
            setDialogs(false);
        }
    };

    function resizeImage(image, maxWidth, maxHeight) {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');

        let width = image.width;
        let height = image.height;

        if (width > height) {
            if (width > maxWidth) {
                height *= maxWidth / width;
                width = maxWidth;
            }
        } else {
            if (height > maxHeight) {
                width *= maxHeight / height;
                height = maxHeight;
            }
        }

        canvas.width = width;
        canvas.height = height;

        ctx.drawImage(image, 0, 0, width, height);

        return canvas.toDataURL('image/jpeg'); // Cambia a 'image/png' si deseas un formato diferente
    }


    return (
        <div className="w-full border border-[3px] border-slate-500 rounded-[1em]" style={{ height: '200px' }}>
            <img
                style={{
                    borderRadius: '.8em',
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover',
                }}
                src={imagenFondo || imagenPerfil}
                onClick={() => setDialogs(true)}
                alt="Profile"
            />
            <Modal className={'m-5 p-5 bg-white rounded-lg shadow-lg'} label={'hola'} isOpen={dialogs} handleClose={() => setDialogs(false)}>
                <div className="p-2 confirmation-content flex flex-col align-items-center">
                    <input
                        type="file"
                        accept=".png, .jpg, .jpeg"
                        onChange={handleFileInputChange}
                        style={{ display: 'none' }}
                        id="fileInput"
                    />
                    {imgCrop && (<p className='text-zinc-500 text-sm -mt-4 py-2 font-bold mb-5'>Puedes arrastrar la imagen para ajustarla.</p>
                    )}
                    {imgCrop && (
                        <AvatarEditor
                            ref={bannerEditorRef}
                            image={imgCrop}
                            style={{ width: '100%', height: '100%' }}
                            width={600}
                            height={300}
                            border={15}
                            color={[0.0, 0.0, 0.0, 0.6]}
                            rotate={0}
                        />
                    )}
                    {!imgCrop && (<div className=' mb-4 text-center flex flex-col sm:flex-row gap-10 mt-5 text-slate-700'>
                        <label htmlFor="fileInput" role='button' className='hover:bg-gray-300 flex-1 bg-slate-200 p-5 gap-3 rounded border border-2 flex flex-col justify-center border-slate-400'>
                            <FontAwesomeIcon icon={faImage} className='text-3xl' />
                            <p className='font-semibold'>Seleccionar de mi PC</p>
                        </label>
                        {/* <div onClick={() => setModalOpen(true)} className='hover:bg-gray-300 cursor-pointer flex-1 bg-slate-200 p-5 gap-3 rounded border border-2 flex flex-col justify-center border-slate-400'>
                            <FontAwesomeIcon icon={faImages} className='text-3xl' />
                            <p className='font-semibold'>Ver galería de Imágenes</p>
                            <Modal
                                disableAnimations={true}
                                className={'m-8 p-1 sm:m-5 max-h-full sm:p-5 bg-white rounded-lg shadow-lg max-w-full'}
                                isOpen={modalOpen}
                                handleClose={() => setModalOpen(false)}
                            >
                                <div className='flex flex-col h-full'>
                                    <div className='p-5 flex-1'>
                                        <p className="text-xl font-bold mb-4">Galería de Imágenes</p>
                                        <div className="grid gap-4 sm:grid-cols-2 lg:h-[60vh] lg:w-[60vw]" style={{ maxHeight: 'calc(90vh - 100px)', overflow: 'auto' }}>
                                            {imageUrls.map((url, index) => (
                                                <div key={index} className='cursor-pointer sm:p-5 sm:bg-slate-200 rounded justify-center flex hover:bg-gray-300' onClick={() => handlePredefinedImageClick(url)}>
                                                    <img loading='lazy' src={url} alt={`Imagen ${index + 1}`} className="w-full" />
                                                </div>
                                            ))}

                                        </div>
                                    </div>
                                    <button className='w-full  p-3 text-xl rounded bg-slate-700 text-white' onClick={() => setModalOpen(false)}>Cancelar</button>
                                </div>
                            </Modal>
                        </div> */}
                    </div>)}
                    <div className="flex mt-4 rounded-md  text-center w-full gap-4">
                        {imgCrop && (
                            <button
                                aria-label='Borrar imagen banner'
                                onClick={deleteImage}
                                className='w-full text-white text-xl bg-red-500 font-semibold rounded'
                            >
                                Eliminar
                            </button>
                        )}
                        <button
                            aria-label='Guardar imagen banner'
                            onClick={saveImage}
                            className='w-full text-white text-xl font-semibold bg-slate-600 rounded py-2'
                        >
                            Guardar
                        </button>
                    </div>
                </div>
            </Modal>
        </div>
    );
};

export default BannerEditor;

