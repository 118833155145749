import React from 'react';
import { LazyMotion, m } from "framer-motion";

const loadFeatures = () =>
    import("./features.js").then(res => res.default)

const AnimatedLetter = ({ letter, color, strokeWidth, width, height, viewBox }) => {
    return (
        <svg width={width} height={height} viewBox={viewBox} fill="none" xmlns="http://www.w3.org/2000/svg">
            {letter.map((path, index) => (
                <LazyMotion features={loadFeatures} key={index}>
                    <m.path
                        d={path}
                        stroke={color}
                        strokeWidth={strokeWidth}
                        strokeLinecap="round"
                        initial={{ pathLength: 0 }}
                        animate={{ pathLength: 1 }}
                        transition={{ duration: 1.8, ease: "easeInOut" }}
                    />
                </LazyMotion>
            ))}
        </svg>
    );
}

export const LogoAnimated = ({ isWhite = false }) => {
    const letterColor = isWhite ? '#FFFFFF' : '#F14902';

    const letterK = [
        "M10 271.178C10 248.155 12.256 102.578 12.256 79.5096C12.256 68.2743 11.8938 56.967 12.256 45.7383C12.4092 40.9872 11.6318 17.3603 12.1652 10.8487C12.2058 10.3527 12.5955 10 13.0932 10C21.1917 10 69.7956 10 73.9997 10",
        "M73.9997 11.9393C73.7704 17.9002 78.123 111.742 73.9997 115.178",
        "M13 173.178C15.7484 170.991 174.358 23.883 177.123 21.6782C179.7 19.1015 260.95 21.6781 265 21.6781",
        "M263 25.6781C261.444 27.5102 10.5 268.634 10.5 271.178",
        "M74.0919 213.154C74.0919 217.319 74.0919 221.484 74.0919 225.649",
        "M74.0919 224.454C73.5797 228.872 72.8542 254.322 72.5682 258.755C72.3802 261.668 71.9838 269.73 72.0972 272.678C72.2114 275.648 79.3679 267.197 80.1591 266.651C84.9611 263.335 90.8519 254.743 95 250.678C99.7677 246.006 140.439 205.528 145.5 201.178C148.154 197.703 223.5 125.827 226.215 122.368C230.485 118.525 234.569 114.817 238.654 110.732C243.004 106.383 261.175 88.9589 265 84.1782",
        "M170.5 178C173.502 178.5 180.375 186.227 182.304 188.276C183.044 188.804 267.782 270.265 267 272.026C266.564 273.006 256.555 272.499 255.775 272.524C253.133 272.609 234.134 272.47 231.5 272.524C226.767 272.524 208.473 272.333 203.858 272.026C201.403 271.862 199.096 272.322 197 271.178C194.185 268.822 172.5 247.178 164.5 237.178C158.5 229.678 147.103 217.16 144 214.678"
    ];

    const letterD = [
        "M20.98 73.0892V21.4999C51.5013 21.4998 114.979 12.0886 118.481 73.0892C116.479 131.589 72.3576 127.865 68.952 127.865H57.4389L20.98 127.865C20.9805 90.9999 20.98 73.0892 20.98 73.0892Z",
    ];

    const letterO = [
        "M20.5001 80C20.1001 37.6 53.5022 19.9998 78.0028 20C102.503 20.0003 136.002 36.8027 136.002 77.4993C136.002 116 106.502 135 78.0028 135C58.002 135 20.9001 122.4 20.5001 80Z",
    ];

    return (
        <div className='flex items-end gap-2 mb-10'>
            <AnimatedLetter letter={letterK} color={letterColor} strokeWidth={20} width='111.2' height='113.6' viewBox='0 0 278 284' />
            <AnimatedLetter letter={letterD} color={letterColor} strokeWidth={40} width='56.8' height='59.6' viewBox='0 0 142 149' />
            <AnimatedLetter letter={letterO} color={letterColor} strokeWidth={40} width='62.8' height='62' viewBox='0 0 157 155' />
        </div>
    );
}
