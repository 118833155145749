import React from 'react';
import Nav from './components/Nav';
import Celular from './components/Celular';
import Tabs from './components/Tabs';
import { ToastContainer } from 'react-toastify';

const App = () => (
  <div className="flex pb-5 flex-col bg-zinc-200 min-h-screen transition-all">
    <ToastContainer />
    <Nav />
    <main className="flex md:py-0 gap-5 md:px-6 flex-1 w-full lg:gap-8">
      <Celular />
      <Tabs />
    </main>
  </div>
);

export default App;
