import React, { useEffect } from 'react';
import lottie from 'lottie-web';

const LottieAnimation = ({ animationData, loop = true, id }) => {
  useEffect(() => {
    const container = document.getElementById(`lottie-container-${id}`);

    if (container) {
      lottie.loadAnimation({
        container,
        animationData,
        loop,
      });
    }
  }, [animationData, loop, id]);

  return <div id={`lottie-container-${id}`} />;
};

export default LottieAnimation;
