import { faFacebook, faWhatsapp, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { faContactBook, faEnvelope } from '@fortawesome/free-solid-svg-icons';


export const socialButtons = [
    {
        icon: faEnvelope,
        color: 'bg-blue-400',
        text: 'Email',
    },
    {
        icon: faFacebook,
        color: 'bg-[#1873EB]',
        text: 'Facebook',
    },
    {
        icon: faWhatsapp,
        color: 'bg-[#01B743]',
        text: 'WhatsApp',
    },
    {
        icon: faLinkedin,
        color: 'bg-[#0A66C2]',
        text: 'LinkedIn',
    },
    {
        icon: faContactBook,
        color: 'bg-[#0A66C2]',
        text: 'Agregar Contacto'
    }
];