import React from 'react';
import { Link } from 'react-router-dom';
import LottieAnimation from '../lottie/LottieAnimation';
import animationData from '../lottie/404.json';
import { LogoAnimated } from '../AnimatedLogo';

const NotFound = () => {
  return (
    <div className="bg-gradient-to-r from-primary to-amber-500 ">
      <div style={{ overflow: 'hidden' }} className='flex flex-col justify-center items-center h-screen'>
        <div className='-mb-10'>
          <LogoAnimated isWhite={true}/>
        </div>
        <LottieAnimation animationData={animationData} loop={false} id={'404'}/>
        <Link
          to="../"
        >
          <div className='px-10'>
            <p className=' text-4xl font-black text-slate-800'>La página que buscas no ha sido encontrada.</p>
            <p className="mt-10 text-2xl py-5 text-center shadow shadow-xl bg-slate-800 rounded-xl font-bold text-white ">
              Volver
            </p>
          </div>
        </Link>
      </div>
    </div>
  )
}

export default NotFound