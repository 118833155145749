import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { URL_BASE_API } from '../redux/DataContext';
import { useParams, useNavigate } from 'react-router-dom';
import { LogoAnimated } from '../AnimatedLogo';

const Verify = () => {
  const { token } = useParams()
  const navigate = useNavigate();

  useEffect(() => {
    fetch(`${URL_BASE_API}verify-account/${token}/`, {
      method: 'GET'
    })
    .then((response) => response.json())
    .then(async response => {
      if (response.status === 404) {
        navigate('/not-found')
        return
      }})
    }, [])


    return (
      <div className="bg-gradient-to-r from-primary to-amber-500">
        <div style={{ overflow: 'hidden' }} className='flex flex-col justify-center items-center h-screen'>
        <div className='-mb-10'>
          <LogoAnimated isWhite={true}/>
        </div>
          <Link
            to="/iniciar-sesion"
          >
            <div className='px-10 pt-20'>
              <p className=' text-4xl font-black text-slate-800'>¡Cuenta Verificada con Éxito!</p>
              <p className="mt-10 text-2xl py-5 text-center shadow shadow-xl bg-slate-800 rounded-xl font-bold text-white ">
                Iniciar Sesión
              </p>
            </div>
          </Link>
        </div>
      </div>
    )
  }

export default Verify