import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faShare } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';

import { URL_BASE } from '../redux/DataContext';

const NavPreview = ({userUrl}) => {
    const handleCopyClick = () => {
        navigator.clipboard.writeText(URL_BASE + '/' + userUrl)
        toast.success('Enlace copiado al portapapeles.', {
            position: "bottom-left",
        });
    };
    return (
        <nav className="bg-gradient-to-r from-primary to-secondary sm:bg-none py-4 lg:mx-10 sm:p-6 sm:bg-transparent transition-all lg:mx-0">
            <ToastContainer />
            <div className="bg-transparent sm:shadow-sm sm:bg-gradient-to-r from-primary to-secondary px-5 flex items-center justify-between sm:px-6 sm:py-4 rounded-xl">
                <Link
                    to={'/'}
                    className=" box-border border-white bg-primary hover:bg-primary transition ease-in-out duration-300 border-secondary border-2 rounded-lg px-4 py-[10px] cursor-pointer hover:bg-orange-400 "
                >
                    <div className="md:hidden">
                        <FontAwesomeIcon
                            icon={faArrowLeft}
                            className="fa-regular fa-image text-xl text-white"
                        />
                    </div>
                    <span className=" hidden md:flex text-white font-bold">Volver</span>
                </Link>
                <div className='flex gap-1'>
                    <Link
                        to={URL_BASE + '/' + userUrl}
                        className="flex items-center flex-col justify-center box-border border-white bg-primary hover:bg-primary transition ease-in-out duration-300 border-secondary border-2 rounded-lg cursor-pointer hover:bg-orange-400 "
                    >
                        <span className="text-sm px-5 text-white font-bold">Ver como invitado</span>
                    </Link>
                    <button
                        onClick={handleCopyClick}
                        className="box-border bg-white hover:bg-orange-100 transition ease-in-out duration-300 border-secondary border-2 rounded-lg px-4 py-[10px] cursor-pointer hover:bg-orange-100"
                    >
                        <div className="md:hidden">
                            <FontAwesomeIcon
                                icon={faShare}
                                className="fa-regular fa-image text-xl text-secondary"
                            />
                        </div>
                        <span className="hidden md:flex text-secondary font-bold">Compartir Enlace</span>
                    </button>
                </div>

            </div>
        </nav>
    );
};

export default NavPreview;
