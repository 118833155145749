import { m, LazyMotion } from "framer-motion";
import React, { useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { ToastContainer, toast } from 'react-toastify';
import { URL_BASE_API } from "../redux/DataContext";

const Reestablecer = () => {
    const [showPassword1, setShowPassword1] = useState(false);
    const [showPassword2, setShowPassword2] = useState(false);
    const [passwordMatchError, setPasswordMatchError] = useState(false);

    const togglePasswordVisibility1 = () => {
        setShowPassword1((prevShowPassword) => !prevShowPassword);
    };
    const togglePasswordVisibility2 = () => {
        setShowPassword2((prevShowPassword) => !prevShowPassword);
    };
    const passwordRef = useRef();
    const repeatPasswordRef = useRef();
    const { token } = useParams();
    const navigate = useNavigate();

    const reestablecer = async (e) => {
        e.preventDefault();
        validateFields()
        let body = {
            password: passwordRef.current.value,
        };
        fetch(`${URL_BASE_API}change-password/${token}/`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(body),
        })
            .then((data) => {
                if (data.status === 202) {
                    navigate("/iniciar-sesion");
                } else if (data.status === 404) {
                    return toast.error("Ha ocurrido un error.");
                } else if (data.status === 401) {
                    return toast.error(
                        "Su token a expirado, debe volver a recuperar contraseña."
                    );
                }
            })
            .catch((error) => {
                return toast.error("Hubo un error al enviar el correo de recuperación.");
            });
    };

    const validateFields = () => {
        const password = passwordRef.current.value;
        const repeatPassword = repeatPasswordRef.current.value;

        if (!password || !repeatPassword) {
            toast.error("Por favor, completa todos los campos.");
            return false;
        }
        if (password !== repeatPassword) {
            setPasswordMatchError(true);
            return false;
        }
        return true;
    };

    const loadFeatures = () =>
        import("../features.js").then((res) => res.default);
    return (
        <div className="bg-white sm:bg-gradient-to-r from-primary to-amber-500 ">
            <ToastContainer />
            <section className="min-h-screen relative">
                <div className="flex flex-col items-center justify-center  md:px-6 py-8 mx-auto h-screen lg:py-0">
                    <LazyMotion features={loadFeatures}>
                        <m.div
                            initial={{ opacity: 0, y: -20 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ duration: 0.6, delay: 0.3 }}
                            className="w-full bg-white rounded-lg sm:shadow sm:border md:mt-0 sm:max-w-md xl:p-0"
                        >
                            <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
                                <h1 className="text-2xl text-center font-bold leading-tight tracking-tight text-gray-900 md:text-3xl text-slate-700">
                                    Reestablecer Contraseña
                                </h1>
                                <form className="space-y-4 md:space-y-6" action="#">
                                    <div className="relative">
                                        <label
                                            htmlFor="password"
                                            className="block mb-2 text-sm font-medium text-slate-800"
                                        >
                                            Nueva Contraseña
                                        </label>
                                        <div className="flex items-center">
                                            <input
                                                ref={passwordRef}
                                                name="password"
                                                id="password"
                                                type={showPassword1 ? "text" : "password"}
                                                className="bg-slate-200 text-gray-900 sm:text-sm rounded-lg block w-full p-2.5 placeholder-gray-400 text-slate-700 shadow-sm focus:outline-none"
                                                required
                                            />
                                            <button
                                                type="button"
                                                className="text-gray-400 hover:text-gray-600 focus:outline-none ml-5"
                                                onClick={togglePasswordVisibility1}
                                            >
                                                <FontAwesomeIcon
                                                    icon={showPassword1 ? faEye : faEyeSlash}
                                                />
                                            </button>
                                        </div>
                                    </div>
                                    <div className="relative">
                                        <label
                                            htmlFor="repeatPassword"
                                            className="block mb-2 text-sm font-medium text-slate-800"
                                        >
                                            Repetir contraseña
                                        </label>
                                        <div className="flex items-center">
                                            <input
                                                ref={repeatPasswordRef}
                                                name="repeatPassword"
                                                id="repeatPassword"
                                                type={showPassword2 ? "text" : "password"}
                                                className="bg-slate-200 text-gray-900 sm:text-sm rounded-lg block w-full p-2.5 placeholder-gray-400 text-slate-700 shadow-sm focus:outline-none"
                                                required
                                            />
                                            <button
                                                type="button"
                                                className="text-gray-400 hover:text-gray-600 focus:outline-none ml-5"
                                                onClick={togglePasswordVisibility2}
                                            >
                                                <FontAwesomeIcon
                                                    icon={showPassword2 ? faEye : faEyeSlash}
                                                />
                                            </button>
                                        </div>
                                    </div>
                                    {passwordMatchError && (
                                        <p className="text-red-500 text-sm">
                                            Las contraseñas no coinciden. Por favor, verifica.
                                        </p>
                                    )}
                                    <button
                                        onClick={reestablecer}
                                        type="submit"
                                        className="w-full text-black bg-primary hover:bg-primary-700 focus:ring-4 text-[19px] text-white focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-[17px] px-5 py-2.5 text-center "
                                    >
                                        Reestablecer Contraseña
                                    </button>
                                </form>
                            </div>
                        </m.div>
                    </LazyMotion>
                </div>
            </section>
        </div>
    );
};

export default Reestablecer;
