import React from 'react';
import ReactDOM from 'react-dom/client';
import Routing from './routes';
import './index.css'
import 'react-toastify/dist/ReactToastify.css';

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Routing />
);
