import React, { useRef, useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEye,
  faEyeSlash,
  faTriangleExclamation,
} from "@fortawesome/free-solid-svg-icons";
import { ToastContainer, toast } from "react-toastify";
import { URL_BASE_API } from "../redux/DataContext";
import DotLoader from "react-spinners/ClipLoader";
import { LazyMotion, m } from "framer-motion";
import { LogoAnimated } from "../AnimatedLogo";

const Register = () => {
  const [passwordMatchError, setPasswordMatchError] = useState(false);
  const [showPassword1, setShowPassword1] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);
  const [isRegistering, setIsRegistering] = useState(false);
  const [showMessage, setShowMessage] = useState(false);

  const navigate = useNavigate();

  const togglePasswordVisibility1 = () => {
    setShowPassword1((prevShowPassword) => !prevShowPassword);
  };
  const togglePasswordVisibility2 = () => {
    setShowPassword2((prevShowPassword) => !prevShowPassword);
  };
  const emailRef = useRef();
  const passwordRef = useRef();
  const repeatPasswordRef = useRef();

  const RegisterUser = (email, password, URL_BASE_API) => {
    const userData = {
      email: email,
      password: password,
      plan_id: 1,
    };

    fetch(URL_BASE_API + "signup/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(userData),
    })
      .then(async (response) => {
        if (response.status === 201) {
          toast.success("¡Registro exitoso! Revise su Email.", {
            onClose: () => {
              navigate("/iniciar-sesion");
            },
          });
          setShowMessage(true);
          setIsRegistering(false);
        } else if (response.status === 409) {
          toast.error("El correo ya está siendo utilizado.");
          setIsRegistering(false);
        } else if (response.status === 400) {
          toast.error("Has excedido el límite de carácteres.");
          setIsRegistering(false);
        }
      })
      .catch((error) => {
        toast.error("Hubo un error al registrarse.");
        setIsRegistering(false);
      });
  };

  const validateFields = () => {
    const email = emailRef.current.value.trim();
    const password = passwordRef.current.value;
    const repeatPassword = repeatPasswordRef.current.value;
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;

    if (!email || !password || !repeatPassword) {
      toast.error("Por favor, completa todos los campos.");
      return false;
    }
    if (!emailRegex.test(email)) {
      toast.error("Por favor, ingresa un correo electrónico válido.");
      return false;
    }
    if (password !== repeatPassword) {
      setPasswordMatchError(true);
      return false;
    }
    return true;
  };
  useEffect(() => {
    setIsRegistering(false);
  }, []);

  const register = async (e) => {
    e.preventDefault();

    if (!validateFields() || isRegistering) {
      return;
    }

    setIsRegistering(true);

    try {
      RegisterUser(
        emailRef.current.value,
        passwordRef.current.value,
        URL_BASE_API
      );
    } catch (error) {
      toast.error("Error para registrarse.");
    }
  };

  const loadFeatures = () =>
    import("../features.js").then((res) => res.default);

  return (
    <div className="bg-gradient-to-r from-primary to-amber-500">
      <ToastContainer />
      <section className="min-h-screen">
        <div className="px-5 bg-white sm:bg-transparent flex flex-col items-center justify-center md:px-6 py-8 mx-auto h-screen lg:py-0">
          {showMessage && (
            <p className="custom-snackbar">
              <FontAwesomeIcon icon={faTriangleExclamation} className="mr-5" />
              <span className="font-bold">Para correos institucionales</span>,
              el correo de confirmación puede tardar hasta 2 horas en llegar.
              <FontAwesomeIcon icon={faTriangleExclamation} className="ml-5" />
            </p>
          )}
          <div className="hidden sm:flex -mt-5">
            <LogoAnimated isWhite={true} />
          </div>
          <div className="sm:hidden -mt-5">
            <LogoAnimated />
          </div>
          <LazyMotion features={loadFeatures}>
            <m.div
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.6, delay: 0.3 }}
              className="w-full bg-white rounded-lg sm:shadow sm:border md:mt-0 sm:max-w-md xl:p-0"
            >
              <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
                <h1 className="text-2xl text-center font-bold leading-tight tracking-tight text-gray-900 md:text-3xl text-slate-700">
                  Regístrate
                </h1>
                <form className="space-y-4 md:space-y-6" action="#">
                  <div className="relative">
                    <label
                      htmlFor="email"
                      className="block mb-2 text-sm font-medium text-slate-800"
                    >
                      Email
                    </label>
                    <input
                      ref={emailRef}
                      type="email"
                      required
                      name="email"
                      id="email"
                      placeholder="correo@email.com"
                      className="bg-slate-200 text-gray-900 sm:text-sm rounded-lg block w-full p-2.5 placeholder-gray-400 text-slate-700 shadow-sm focus:outline-none"
                    />
                  </div>
                  <div className="relative">
                    <label
                      htmlFor="password"
                      className="block mb-2 text-sm font-medium text-slate-800"
                    >
                      Contraseña
                    </label>
                    <div className="flex items-center">
                      <input
                        ref={passwordRef}
                        name="password"
                        id="password"
                        type={showPassword1 ? "text" : "password"}
                        className="bg-slate-200 text-gray-900 sm:text-sm rounded-lg block w-full p-2.5 placeholder-gray-400 text-slate-700 shadow-sm focus:outline-none"
                        required
                      />
                      <button
                        aria-label="Mostrar primera contraseña"
                        type="button"
                        className="text-gray-400 hover:text-gray-600 focus:outline-none ml-5"
                        onClick={togglePasswordVisibility1}
                      >
                        <FontAwesomeIcon
                          icon={showPassword1 ? faEye : faEyeSlash}
                        />
                      </button>
                    </div>
                  </div>
                  <div className="relative">
                    <label
                      htmlFor="repeatPass"
                      className="block mb-2 text-sm font-medium text-slate-800"
                    >
                      Repetir contraseña
                    </label>
                    <div className="flex items-center">
                      <input
                        ref={repeatPasswordRef}
                        name="repeatPass"
                        id="repeatPass"
                        type={showPassword2 ? "text" : "password"}
                        className="bg-slate-200 text-gray-900 sm:text-sm rounded-lg block w-full p-2.5 placeholder-gray-400 text-slate-700 shadow-sm focus:outline-none"
                        required
                      />
                      <button
                        aria-label="Mostrar segunda contraseña"
                        type="button"
                        className="text-gray-400 hover:text-gray-600 focus:outline-none ml-5"
                        onClick={togglePasswordVisibility2}
                      >
                        <FontAwesomeIcon
                          icon={showPassword2 ? faEye : faEyeSlash}
                        />
                      </button>
                    </div>
                  </div>
                  {passwordMatchError && (
                    <p className="text-red-500 text-sm">
                      Las contraseñas no coinciden. Por favor, verifica.
                    </p>
                  )}
                  <button
                    onClick={register}
                    type="submit"
                    className={`w-full bg-slate-700 focus:ring-4 text-[19px] text-white focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center ${
                      isRegistering ? "cursor-not-allowed opacity-50" : ""
                    }`}
                    disabled={isRegistering}
                  >
                    {isRegistering ? (
                      <>
                        Registrando... <DotLoader size={20} color="#fff" />
                      </>
                    ) : (
                      "Registrarse"
                    )}
                  </button>

                  <Link to="/iniciar-sesion">
                    <p className="text-sm font-light text-gray-500 pt-6 ">
                      ¿Ya tienes cuenta?{" "}
                      <span className="font-medium text-slate-700 hover:underline">
                        Inicia Sesión
                      </span>
                    </p>
                  </Link>
                </form>
              </div>
            </m.div>
            <div className="mt-5">
              <a
                href="https://gealytics.cl"
                target="_blank"
                className="text-left w-full mt-10 text-slate-500 sm:text-gray-100 text-xs cursor-pointer"
              >
                Powered by <span className="font-bold">Gealytics</span>
              </a>
            </div>
          </LazyMotion>
        </div>
      </section>
    </div>
  );
};

export default Register;
