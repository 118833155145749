import React, { useState } from 'react';
import { DragDropContext, Droppable } from '@hello-pangea/dnd';
import EnlaceItem from '../components/EnlaceItem';
import { useDataContext } from '../redux/DataContext.jsx';
import { PremiumModal } from '../components/PremiumModal';
import { toast } from 'react-toastify';

const Enlaces = () => {
  const {
    linksData,
    setLinksData,
    divHeight,
    profileData,
    URL_BASE_API,
  } = useDataContext();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [links, setLinks] = useState(linksData);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  const addLink = () => {
    if (profileData.plan === 1 && links.length >= 2) {
      openModal();
    } else {
      const newLink = { id: String(Date.now()), url: '', isNew: true };
      setLinks([...links, newLink]);
    }
  };

  const removeLink = async (id) => {
    try {
      await fetch(`${URL_BASE_API}delete-link/${id}/`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Token ${localStorage.getItem('auth')}`,
        },
      });
      const updatedLinks = links.filter((link) => link.id !== id);
      setLinks(updatedLinks);
      setLinksData(updatedLinks);
    } catch (error) {
      toast.error('Ha ocurrido un error.');
    }
  };

  const reorderLinks = (list, startIndex, endIndex) => {
    const updatedLinks = [...list];
    const [removedLink] = updatedLinks.splice(startIndex, 1);
    updatedLinks.splice(endIndex, 0, removedLink);
    return updatedLinks;
  };

  const updateIndexesInDatabase = async (updatedLinks) => {
    for (let i = 0; i < updatedLinks.length; i++) {
      const link = updatedLinks[i];
      const linkData = { index: i };
      try {
        await fetch(`${URL_BASE_API}update-link/${link.id}/`, {
          method: 'PATCH',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Token ${localStorage.getItem('auth')}`,
          },
          body: JSON.stringify(linkData),
        });
      } catch (error) {
        toast.error('Ha ocurrido un error.')
      }
    }
  };

  const onDragEnd = (result) => {
    if (!result.destination) return;
    const updatedLinks = reorderLinks(links, result.source.index, result.destination.index);
    updateIndexesInDatabase(updatedLinks);
    setLinks(updatedLinks);
    setLinksData(updatedLinks);
  };

  const onDragStart = (initial) => {
    const sourceLink = links[initial.source.index];
    if (sourceLink.isNew) {
      toast.warning('Debes guardar primero el enlace para cambiar de lugar.');
      return false;
    }
  };

  const hasNewLinks = links.some((link) => link.isNew);

  return (
    <div className="w-full sm:px-6 md:px-0 sm:mx-auto lg:px-0">
      <PremiumModal isOpen={isModalOpen} handleClose={closeModal} />
      <div
        style={{ minHeight: window.innerWidth >= 1024 ? `${divHeight}px` : '' }}
        className="flex flex-col bg-white p-6 sm:rounded-b-xl text-center text-gray-500 dark:text-gray-400"
      >
        <div className="text-left">
          <h1 className="text-3xl  font-bold mb-2 flex justify-start text-slate-700">Añade tus Redes Sociales</h1>
          <p className="text-lg text-slate-500 flex justify-start">¡Añade tus Redes Sociales a continuación y comparte tus perfiles con el mundo!</p>
        </div>
        <button
          onClick={addLink}
          className="w-full text-secondary rounded border border-secondary border-2 py-2 mt-6 px-4 font-medium text-xl mb-5"
        >
          + Añadir Red Social
        </button>
        <DragDropContext onDragEnd={onDragEnd} onDragStart={onDragStart}>
          <Droppable droppableId="links" isDropDisabled={hasNewLinks}>
            {(provided, snapshot) => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                className={`linkContainer ${snapshot.isDraggingOver ? 'dragging-over' : ''}`}
              >
                {links.map((link, index) => (
                  <EnlaceItem key={link.id} links={links} setLinks={setLinks} link={link} index={index} removeLink={removeLink} />
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
    </div>
  );
};

export default Enlaces;
