
import React, { useState } from 'react';
import { useDataContext } from '../redux/DataContext.jsx'
import { DragDropContext, Droppable } from '@hello-pangea/dnd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleInfo } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
import { Tooltip } from 'react-tooltip'
import EnlaceItem from '../components/MediaItem';

const Media = () => {
  const {
    mediaData,
    setMediaData,
    divHeight,
    profileData,
    URL_BASE_API
  } = useDataContext()

  const [links, setLinks] = useState(mediaData);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const addLink = () => {
    if (profileData.plan === 1 && links.length >= 2) {
      setIsModalOpen(true);
      return;
    }

    const newLink = { id: String(Date.now()), platform: '', url: '', isNew: true };

    setLinks([...links, newLink]);
  };

  const removeLink = id => {
    fetch(`${URL_BASE_API}delete-multimedia/${id}/`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Token ${localStorage.getItem('auth')}`
      }
    })
    .then((response) => response.json())
      .then(data => {
        const updatedLinks = links.filter(link => link.id !== id);
        setLinks(updatedLinks);
        setMediaData(updatedLinks)
      }).catch(error => {
        toast.error('Ha ocurrido un error.')
      })

  };
  const reorderLinks = (list, startIndex, endIndex) => {
    const updatedLinks = Array.from(list);
    const [removedLink] = updatedLinks.splice(startIndex, 1);
    updatedLinks.splice(endIndex, 0, removedLink);
    return updatedLinks;
  };

  const updateIndexesInDatabase = async updatedLinks => {
    for (let i = 0; i < updatedLinks.length; i++) {
      const link = updatedLinks[i];
      const linkData = {
        index: i
      };
      try {
        await fetch(`${URL_BASE_API}update-multimedia/${link.id}/`, {
          method: 'PATCH',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Token ${localStorage.getItem('auth')}`
          },
          body: JSON.stringify(linkData)
        });
      } catch (error) {
      }
    }
  };

  const onDragEnd = result => {
    if (!result.destination) {
      return;
    }

    const updatedLinks = reorderLinks(
      links,
      result.source.index,
      result.destination.index
    );
    updateIndexesInDatabase(updatedLinks)
    setLinks(updatedLinks);
    setMediaData(updatedLinks)
  };

  const onDragStart = (initial) => {
    const sourceLink = links[initial.source.index];
    if (sourceLink.isNew) {
      toast.warning('Debes guardar primero el enlace para cambiar de lugar.');
      return false;
    }
  };

  const asd = () => {
    if (links.some(link => link.isNew)) {
      return true
    }
    return false
  }

  return (
    <div className="w-full sm:px-6 md:px-0 sm:mx-auto sm:mb-6 lg:px-0">
      <div style={{ minHeight: window.innerWidth >= 1024 ? `${divHeight}px` : '' }}
        className={`flex flex-col bg-white p-6 sm:rounded-b-xl text-center text-gray-500 dark:text-gray-400`}>
        <div className="text-left">
          <h1 className="text-3xl  font-bold mb-2 flex justify-start text-slate-700 items-center">Contenido Audiovisual
            <p data-tooltip-id="my-tooltip" data-tooltip-content="Plataformas soportadas: Youtube - Instagram - Twitter - Facebook - Linkedin - Pinterest">
              <FontAwesomeIcon icon={faCircleInfo} data-tooltip-target="tooltip-top" data-tooltip-placement="top" className='text-[0.7em] opacity-50 ml-3' />
            </p>
            <Tooltip id="my-tooltip" place='top' style={{ fontSize: '.4em', width: '15em' }} />
          </h1>
          <div id="tooltip-top" role="tooltip" className="absolute z-10 invisible inline-block px-3 py-2 text-sm font-medium text-white bg-gray-900 rounded-lg shadow-sm opacity-0 tooltip dark:bg-gray-700">
            Tooltip on top
            <div className="tooltip-arrow" data-popper-arrow></div>
          </div>
          <p className="text-lg text-slate-500 flex justify-start">¡Dale vida a tu perfil y muestra tu trabajo!
          </p>
        </div>
        <button
          onClick={() => addLink()}
          className="w-full text-secondary rounded border border-secondary border-2 py-2 mt-6 px-4 font-medium text-xl mb-5"
        >
          + Añadir enlace
        </button>
        <DragDropContext onDragEnd={onDragEnd} onDragStart={onDragStart}>
          <Droppable droppableId="links" isDropDisabled={asd()}>
            {(provided, snapshot) => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                className={`linkContainer ${snapshot.isDraggingOver ? 'dragging-over' : ''}`}
              >
                {links.map((link, index) => (
                  <EnlaceItem key={`${link.id}`} links={links} setLinks={setLinks} link={link} index={index} removeLink={removeLink} />
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
    </div>
  );
};

export default Media;
