import React, { useState, useEffect, useRef, Fragment } from 'react';
import { Draggable } from '@hello-pangea/dnd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { useDataContext } from '../redux/DataContext';

const EnlaceItem = ({ links, setLinks, link, index, removeLink }) => {

    const { socialMedias, setLinksData, linksData, profileData, URL_BASE_API, URL_BASE } = useDataContext()

    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [linkInputValue, setLinkInputValue] = useState(link.url_link || '');
    const [messageInputValue, setMessageInputValue] = useState(link.message || '');

    const [isNew, setIsNew] = useState(link.isNew);
    const [isModified, setIsModified] = useState(false);

    const dropdownRef = useRef(null);
    const enlaceRef = useRef()
    const mensajeRef = useRef()

    const [platformOptions, setPlatformOptions] = useState([]);
    const [selectedPlatform, setSelectedPlatform] = useState(link.socialmedia ? link.socialmedia.name || '' : '');
    const [selectedPlatformId, setSelectedPlatformId] = useState()

    useEffect(() => {
        if (socialMedias) {
            setPlatformOptions(socialMedias);
        }
    }, [socialMedias]);

    function createLink(linkData) {
        fetch(`${URL_BASE_API}create-link/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${localStorage.getItem('auth')}`
            },
            body: JSON.stringify(linkData)
        }).then((response) => response.json())
            .then(data => {
                if (data.error) {
                    return
                }

                setLinksData(prevLinks => [...prevLinks, data])

                let elements = [...links]
                let currentElementIndex = elements.findIndex((x) => x.id === link.id);
                if (currentElementIndex === -1) return;
                elements[currentElementIndex] = data

                setLinks(elements)

                setIsNew(false);
                setIsModified(false);
            })
            .catch(error => {
                console.error('Error al crear enlace:', error);
            });
    }

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutsideDropdown);
        return () => {
            document.removeEventListener('mousedown', handleClickOutsideDropdown);
        };
    }, [link.id]);

    const toggleDropdown = () => {
        setIsDropdownOpen(!isDropdownOpen);
    };

    const handlePlatformSelection = (platform, id) => {
        setSelectedPlatform(platform);
        setSelectedPlatformId(id);
        setIsModified(true);
        setIsDropdownOpen(false);
    };

    const handleInputChange = (e) => {
        setLinkInputValue(e.target.value);
        setIsModified(true);
    };

    const handleMessageChange = (e) => {
        setMessageInputValue(e.target.value);
        setIsModified(true)
    }

    const updateLink = (linkId, linkData) => {
        return fetch(`${URL_BASE_API}update-link/${linkId}/`, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${localStorage.getItem('auth')}`
            },
            body: JSON.stringify(linkData)
        })
            .then((response) => response.json())
            .then(data => {

                let elements = [...linksData]
                let currentElementIndex = elements.findIndex((x) => x.id === linkId);
                if (currentElementIndex === -1) return;
                elements[currentElementIndex] = data

                setLinksData(elements)
                setLinks(elements)
            })
            .catch(error => {
                console.error('Error al actualizar enlace:', error);
            });
    }

    const handleUpdateLink = () => {
        const enlaceValue = enlaceRef.current.value;

        const linkData = {
            socialmedia: selectedPlatformId,
            url_link: enlaceValue,
        };

        if (messageInputValue) {
            linkData.message = mensajeRef.current.value;
        }

        updateLink(link.id, linkData)
            .then(() => {
                setIsModified(false);
            });
    };

    const handleSaveLink = () => {
        const enlaceValue = enlaceRef.current.value;

        const linkData = {
            social_media_id: selectedPlatformId,
            url_link: enlaceValue,
            index: index
        }
        if (messageInputValue) {
            linkData.message = mensajeRef.current.value;
        }

        createLink(linkData);
    }

    const handleClickOutsideDropdown = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setIsDropdownOpen(false);
        }
    };

    return (
        <Draggable key={link.id} draggableId={`${link.id}`} index={index}>
            {(provided, snapshot) => (
                <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    className={`shadow-sm shadow-slate-400 bg-gray-200 px-4 py-3 rounded-lg mb-5 ${snapshot.isDragging ? 'dragging' : ''}`}
                >
                    <div className="flex items-center justify-between mb-2">
                        <div className="flex items-center">
                            <FontAwesomeIcon icon={faBars} className=" mr-3  text-slate-600 text-xl" />
                            <p className="text-gray-700 font-bold mr-4">Red Social #{index + 1}</p>
                        </div>
                        <button
                            aria-label='Borrar red social'
                            className="text-gray-500 hover:bg-gray-100 rounded-xl transition ease-in-out  duration-400 hover:border-white border p-2"
                            onClick={() => removeLink(link.id)}
                        >
                            <p className='text-gray-500 font-medium'>Remover</p>
                        </button>
                    </div>
                    <div className="bg-gray-200 p-2 mt-2">
                        <div className="flex flex-col items-start mb-2">
                            <label className="text-slate-700 mb-2">Red Social</label>
                            <div className="w-full">
                                <div className="flex relative" ref={dropdownRef}>
                                    <button
                                        aria-label='Selecciona una Red Social'
                                        id={`platforms-button-${link.id}`}
                                        className=" flex-shrink-2 z-10  inline-flex  items-center justify-between w-full py-2.5 px-4 text-md text-left text-slate-600 rounded-lg bg-white  border border-1 border-gray-400"
                                        type="button"
                                        onClick={toggleDropdown}
                                    >
                                        {selectedPlatform || 'Selecciona una Red Social'}

                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            className="h-5 w-5 ml-2 text-slate-400"
                                            viewBox="0 0 20 20"
                                            fill="currentColor"
                                            aria-hidden="true"
                                        >
                                            <path
                                                fillRule="evenodd"
                                                d="M10 15a1 1 0 01-.707-.293l-4-4a1 1 0 111.414-1.414L10 12.586l3.293-3.293a1 1 0 111.414 1.414l-4 4A1 1 0 0110 15z"
                                                clipRule="evenodd"
                                            />
                                        </svg>
                                    </button>
                                    {isDropdownOpen && (
                                        <div
                                        className="z-10 py-1 w-full absolute bg-white divide-y divide-gray-400 rounded-md shadow "
                                        style={{ top: 'calc(100% + 10px)', left: 0, zIndex: 10000}}
                                    >
                                            {platformOptions.map((platform) => {
                                                if (profileData.plan === 1 && (platform.name === "WhatsApp" || platform.name === "Correo Electrónico")) {
                                                    return null; // No renderizar el botón para estas plataformas
                                                } else {
                                                    return (
                                                        <button
                                                            key={platform.id}
                                                            type="button"
                                                            aria-label={`Red Social ${platform.name}`}
                                                            className={`w-full px-4 py-2 text-md text-left hover:bg-gray-100
                                                                ${selectedPlatform === platform.name
                                                                    ? 'text-secondary font-medium '
                                                                    : 'bg-white text-slate-500 '
                                                                }`}
                                                            onClick={() => handlePlatformSelection(platform.name, platform.id)}
                                                        >
                                                            <div className='flex items-center gap-5'>
                                                                <div className='w-5 h-5'>
                                                                    <img src={`${URL_BASE}${platform.image}`} alt="" />
                                                                </div>
                                                                {platform.name}
                                                            </div>
                                                        </button>
                                                    );
                                                }
                                            })}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-col items-start mt-2 ">
                            <label className="text-slate-700 mb-2">{selectedPlatform === "WhatsApp" ? 'Número' : selectedPlatform === "Correo Electrónico" ? 'Asunto del Correo' : 'Link a la Red Social'}</label>
                            
                            <input
                                ref={enlaceRef}
                                type="text"
                                className="w-full bg-white px-4 py-2.5 rounded-lg focus:outline-secondary border border-1 border-gray-400"
                                placeholder={selectedPlatform === "WhatsApp" ? 'Número (ej: 569XXXXXXXX)' : selectedPlatform == 'Correo Electrónico' ? 'Asunto' : 'Enlace'}
                                value={linkInputValue}
                                onChange={(e) => handleInputChange(e)}
                            />

                            {(selectedPlatform === "WhatsApp" || selectedPlatform === "Correo Electrónico") && (
                                <Fragment>
                                    <label className="text-slate-700 mb-2">Mensaje</label>
                                    <input
                                        ref={mensajeRef}
                                        type="text"
                                        className="w-full bg-white px-4 py-2.5 rounded-lg focus:outline-secondary border border-1 border-gray-400"
                                        placeholder="Mensaje personalizado."
                                        value={messageInputValue}
                                        onChange={(e) => handleMessageChange(e)}
                                    />
                                </Fragment>
                            )}
                        </div>
                    </div>
                    <div className="flex mt-3">
                        <button
                            aria-label={isNew ? `Guardar red social` : `Actualizar red social`}
                            className={`bg-slate-700 w-full text-white font-bold px-5 text-md py-2 rounded-md ${isNew || isModified ? '' : 'opacity-50 cursor-not-allowed'}`}
                            onClick={isNew ? handleSaveLink : handleUpdateLink}
                            disabled={!isNew && !isModified}
                        >
                            {isNew ? `Guardar` : `Actualizar`}
                        </button>
                    </div>
                </div>
            )}
        </Draggable>
    );
};

export default EnlaceItem;
