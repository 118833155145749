import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faLink, faCirclePlay, faGem } from '@fortawesome/free-solid-svg-icons';
import Perfil from '../pages/Perfil';
import Enlaces from '../pages/Enlaces';
import Media from '../pages/Media';
import { useDataContext } from '../redux/DataContext';
import { PremiumModal } from './PremiumModal';

const Tabs = () => {
    const [underlinePosition, setUnderlinePosition] = useState(0);
    const { activeTab, setActiveTab, profileData, setTabHeight } = useDataContext();
    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleTabClick = (tab, index) => {
        if (tab === 'Productos y Servicios' && profileData.plan === 1) {
            setIsModalOpen(true);
        } else {
            setActiveTab(tab);
            setUnderlinePosition(index);
        }
    };

    useEffect(() => {
        const targetDiv = document.querySelector('#tabDiv');
        if (targetDiv) {
            setTabHeight(targetDiv.offsetHeight);
        }
    }, [setTabHeight]);

    const tabIcons = {
        Perfil: faUser,
        "Redes Sociales": faLink,
        "Productos y Servicios": faCirclePlay,
    };

    return (
        <div className="w-full relative lg:mr-10">
            <ol id='tabDiv' className=" shadow-sm relative lg:mx-0 sm:mx-6 md:mx-0 m-auto sm:rounded-t-lg flex bg-white font-medium text-center text-gray-500" style={{ justifyContent: 'space-between' , alignItems: 'center'}}>
                {['Perfil', 'Redes Sociales', 'Productos y Servicios'].map((tab, index) => (
                    <li key={tab} className={`flex-1 ${index !== 0 ? '-ml-px' : ''}`}>
                        <div
                            className={`tab-link flex items-center p-4 ${activeTab === tab ? 'text-primary active' : 'hover:text-gray-600 hover:border-gray-300 group'
                                } text-sm sm:text-lg cursor-pointer justify-center`}
                            onClick={() => handleTabClick(tab, index)}
                        >
                            <div className={`flex items-center ${activeTab === tab ? 'text-primary' : 'text-slate-700'}`}>
                                {tab === 'Productos y Servicios' && profileData && profileData.plan === 1 && (
                                    <FontAwesomeIcon
                                        icon={faGem}
                                        className={`hidden sm:block h-4 w-4 mr-2 sm:h-5 sm:w-5 sm:mr-5 text-primary group`}
                                    />
                                )}
                                <FontAwesomeIcon
                                    icon={tabIcons[tab]}
                                    className={`h-4 w-4 mr-2 sm:h-5 sm:w-5 xl:mr-9 ${activeTab === tab ? 'text-primary group' : 'text-slate-700'}`}
                                />
                                <span>
                                    {tab}
                                </span>
                            </div>

                        </div>
                    </li>
                ))}
                <div
                    className="absolute bottom-0 h-1 bg-primary rounded"
                    style={{
                        left: `${underlinePosition * 33.33}%`,
                        width: '33.33%',
                        transition: 'left 0.2s ease-in-out',
                    }}
                />

            </ol>
            <div id="modal-portal"></div>
            <PremiumModal isOpen={isModalOpen} handleClose={() => setIsModalOpen(false)}/>
            {activeTab === 'Perfil' && <Perfil />}
            {activeTab === 'Redes Sociales' && <Enlaces />}
            {activeTab === 'Productos y Servicios' && <Media />}
        </div>
    );
};

export default Tabs;
