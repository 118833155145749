import React, { createContext, useState, useContext, useEffect } from "react";
import { toast } from 'react-toastify';
const DataContext = createContext();

export const URL_BASE_API = 'https://kdo.cl/api/'
export const URL_BASE = 'https://kdo.cl'

// export const URL_BASE_API = 'http://192.168.1.117:3001/api/'
// export const URL_BASE = 'http://192.168.1.117:3001'

// export const URL_BASE_API = 'http://localhost:8000/api/'
// export const URL_BASE = 'http://localhost:8000'

export const useDataContext = () => {
  return useContext(DataContext);
};

const DataProvider = ({ children }) => {
  const [profileData, setProfileData] = useState(null);
  const [linksData, setLinksData] = useState(null);
  const [mediaData, setMediaData] = useState(null);
  const [socialMedias, setSocialMedias] = useState(null);
  const [activeTab, setActiveTab] = useState('Perfil');
  const [divHeight, setDivHeight] = useState(0);
  const [tabHeight, setTabHeight] = useState(0);

  useEffect(() => {
    verifySubscription()
    getLinks()
    getMedias()
    getSocialMedias()
    getProfileData()
  }, []);

  const verifySubscription = () => {
    fetch(`${URL_BASE_API}verify-suscription/`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Token ${localStorage.getItem('auth')}`
      }
    }).then((response) => response.json())
      .then(res => {
        if (res.actualizar) {
          localStorage.clear()
          window.location.href = '/iniciar-sesion'

        }
      }).catch(error => {
        toast.error('Ha ocurrido un error');
      })
  }

  const getProfileData = () => {
    fetch(`${URL_BASE_API}get-data-card/`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Token ${localStorage.getItem('auth')}`
      }
    }).then((response) => response.json())
      .then((data) => {
        setProfileData(data)
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  }

  const getLinks = () => {
    fetch(`${URL_BASE_API}get-links/`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Token ${localStorage.getItem('auth')}`
      }
    })
    .then((response) => response.json())
      .then(data => {
        const linksFromDatabase = data.map(link => ({ ...link, isNew: false }))
        const sortedLinks = linksFromDatabase.sort((a, b) => a.index - b.index)
        setLinksData(sortedLinks)
      })
      .catch(error => {
        console.error('Error fetching platform options:', error);
      });
  }

  const getMedias = () => {
    fetch(`${URL_BASE_API}get-multimedias/`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Token ${localStorage.getItem('auth')}`
      }
    })
    .then((response) => response.json())
      .then(data => {
        const linksFromDatabase = data.map(link => ({ ...link, isNew: false }));
        const sortedLinks = linksFromDatabase.sort((a, b) => a.index - b.index)
        setMediaData(sortedLinks)
      })
      .catch(error => {
        console.error('Error fetching platform options:', error);
      });
  }

  const getSocialMedias = () => {
    fetch(`${URL_BASE_API}get-social-medias/`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Token ${localStorage.getItem('auth')}`
      }
    }).then((response) => response.json())
      .then(data => {
        setSocialMedias(data);
      })
      .catch(error => {
        console.error('Error fetching platform options:', error);
      });
  };

  const value = {
    profileData,
    setProfileData,
    linksData,
    setLinksData,
    mediaData,
    setMediaData,
    socialMedias,
    activeTab,
    setActiveTab,
    divHeight,
    setDivHeight,
    tabHeight,
    setTabHeight,
    URL_BASE_API,
    URL_BASE
  }

  return (
    <DataContext.Provider value={value}>{children}</DataContext.Provider>
  );
};

export default DataProvider;
