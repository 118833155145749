import React from 'react';
import { useDataContext } from '../redux/DataContext';

const Celular = () => {
    const {
        profileData,
        linksData,
        divHeight,
        tabHeight,
        URL_BASE
    } = useDataContext();
    function hexToRgb(hex) {
        // Elimina el signo de almohadilla (#) si está presente
        hex = hex.replace(/^#/, '');

        // Parsea los componentes de color en valores hexadecimales
        let bigint = parseInt(hex, 16);

        // Extrae los componentes R, G y B
        let r = (bigint >> 16) & 255;
        let g = (bigint >> 8) & 255;
        let b = bigint & 255;

        return `${r}, ${g}, ${b}`;
    }

    return (
        <div
            style={{ height: divHeight + tabHeight, position: 'sticky', top: '2em', }}
            className=" shadow- lg:ml-10 hidden sticky md:flex items-center justify-center bg-white w-[600px] rounded-xl">
            <div className=' w-full h-full lg:p-8'>
                <div className=" w-full h-full rounded-xl border border-[10px] border-slate-800 overflow-auto">
                    {/* aqui comienza */}
                    <div className="pb-10">
                        <div className="flex flex-col relative"
                            style={{
                                backgroundImage: profileData && profileData.profile_banner ? `url('${URL_BASE}${profileData.profile_banner}')` : 'none',
                                backgroundSize: 'cover',
                                backgroundPosition: 'center',
                            }}
                        >
                            <div
                                className="banner-overlay"
                                style={{
                                    backgroundColor: profileData && profileData.profile_color && profileData.profile_banner !== null
                                    ? `rgba(${hexToRgb(profileData.profile_color)}, 0.5)`
                                    : profileData && profileData.profile_color,
                                    position: 'absolute',
                                    top: 0,
                                    left: 0,
                                    width: '100%',
                                    height: '100%',
                                }}
                            ></div>
                            <div className="z-10 mx-auto flex flex-col gap-3 items-center px-6 py-6 text-white text-center self-center">
                                <h1
                                    style={{
                                        color: profileData && profileData.text_color ? profileData.text_color : '#000000',
                                    }}
                                    className="mt-2 text-2xl font-semibold">
                                    {profileData && profileData.profile_name ? profileData.profile_name : 'Nombre'}
                                </h1>
                                <div
                                    className="w-40 h-40 bg-gray-300 rounded-full border md:w-56 md:h-56 border-[6px] border-white"
                                    style={{
                                        backgroundImage: profileData && profileData.profile_image ? `url('${URL_BASE}${profileData.profile_image}')` : 'none',
                                        backgroundSize: 'cover',
                                        backgroundPosition: 'center',
                                    }}
                                ></div>
                                <p
                                    style={{
                                        color: profileData && profileData.text_color ? profileData.text_color : '#000000',
                                    }}
                                    className="text-xl font-semibold  overflow-hidden">
                                    {profileData && profileData.profile_email ? profileData.profile_email : 'correo@correo.com'}
                                </p>
                                <div className="w-full text-center m-auto">
                                    <p
                                        className="text-sm sm:text-[17px] p-3"
                                        style={{
                                            wordWrap: 'break-word',
                                            color: profileData && profileData.text_color ? profileData.text_color : '#000000',
                                        }}
                                    >
                                        {profileData && profileData.profile_description}
                                    </p>
                                </div>
                            </div>
                            <div data-negative="true" className='z-10'>
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 100" preserveAspectRatio="none">
                                    <path
                                        fill='#FFFFFF'
                                        d="M500.2,94.7L0,0v100h1000V0L500.2,94.7z"
                                    ></path>
                                </svg>
                            </div>
                        </div>
                    </div>

                    <div className="max-w-lg mt-8 flex flex-col px-5 items-center">
                        {linksData && linksData.map((e) => e.socialmedia && (
                            <div
                                key={e.id}
                                style={{ backgroundColor: e.socialmedia.color }}
                                className='flex items-center px-3 bg-gray-200 h-[40px] w-full rounded mb-3'
                            >
                                <div className='h-10 w-10 flex items-center'>
                                    <img src={`${URL_BASE}${e.socialmedia.image}`} alt="" />
                                </div>
                                <p className='text-center w-full text-white' key={e.id}>
                                    {e.socialmedia.name}
                                </p>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Celular;
