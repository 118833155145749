import React, { useState, useEffect } from 'react'
import NavPreview from '../components/NavPreview'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { socialButtons } from '../socialButtons';
import { QRCodeSVG } from 'qrcode.react';
import { useParams, useNavigate } from 'react-router-dom';
import { FacebookEmbed, InstagramEmbed, TikTokEmbed, TwitterEmbed, YouTubeEmbed, LinkedInEmbed, PinterestEmbed } from 'react-social-media-embed';
import { URL_BASE_API, URL_BASE } from '../redux/DataContext';

const Preview = ({ userData }) => {

    const { user_url, is_preview } = useParams()
    console.log(userData)

    const navigate = useNavigate();
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const handleResize = () => {
        setWindowWidth(window.innerWidth);
    };
    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    function hexToRgb(hex) {
        hex = hex.replace(/^#/, '');
        let bigint = parseInt(hex, 16);
        let r = (bigint >> 16) & 255;
        let g = (bigint >> 8) & 255;
        let b = bigint & 255;
        return `${r}, ${g}, ${b}`;
    }

    return (
        <>
            {
                is_preview && is_preview == 'preview' && <NavPreview userUrl={user_url} />
            }
            <div style={{ overflowX: 'hidden' }}>
                <div className="pb-10">
                    <div className="flex flex-col relative"
                        style={{
                            backgroundImage: userData && userData.data.profile_banner ? `url('${URL_BASE}${userData.data.profile_banner}')` : 'none',
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                        }}
                    >
                        <div
                            className="banner-overlay"
                            style={{
                                backgroundColor: userData && userData.data.profile_color && userData.data.profile_banner !== null
                                    ? `rgba(${hexToRgb(userData.data.profile_color)}, 0.5)`
                                    : userData && userData.data.profile_color,
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                width: '100%',
                                height: '100%',
                                zIndex: 1,
                            }}
                        ></div>
                        <div style={{ zIndex: 10 }} className="max-w-3xl mx-auto flex flex-col gap-3 items-center px-6 py-6 md:py-3 text-white text-center self-center">
                            <h1
                                style={{
                                    color: userData && userData.data.text_color ? userData.data.text_color : '#000000',
                                }}
                                className="mt-5 text-3xl font-semibold md:text-[3em] md:pt-6 md:mb-5">
                                {userData && userData.data.profile_name ? userData.data.profile_name : 'Nombre'}
                            </h1>
                            <div
                                className="w-40 h-40 bg-gray-300 rounded-full border md:w-56 md:h-56 border-[6px] border-white"
                                style={{
                                    backgroundImage: userData && userData.data.profile_image ? `url('${URL_BASE}${userData.data.profile_image}')` : 'none',
                                    backgroundSize: 'cover',
                                    backgroundPosition: 'center',
                                }}
                            ></div>
                            <p
                                style={{
                                    color: userData && userData.data.text_color ? userData.data.text_color : '#000000',
                                }}
                                className="text-xl font-semibold sm:text-lg lg:text-xl md:pt-5 overflow-hidden">
                                {userData && userData.data.profile_email ? userData.data.profile_email : 'correo@correo.com'}
                            </p>
                            <div className="w-full text-center m-auto lg:mt-3">
                                <p
                                    className="text-sm sm:text-[17px] p-3 sm:p-2 font-medium"
                                    style={{
                                        color: userData && userData.data.text_color ? userData.data.text_color : '#000000',
                                        wordWrap: 'break-word',
                                    }}
                                >
                                    {userData && userData.data.profile_description}
                                </p>
                            </div>
                        </div>
                        <div style={{ zIndex: 200 }}
                            className={`${userData && userData.data.profile_description
                                ? 'lg:-mt-[3em]'
                                : 'lg:-mt-[6em]'
                                }`}
                            data-negative="true"
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 100" preserveAspectRatio="none">
                                <path
                                    fill='#FFFFFF'
                                    d="M500.2,94.7L0,0v100h1000V0L500.2,94.7z"
                                ></path>
                            </svg>
                        </div>
                    </div>
                    <div className=" mx-auto max-w-lg mt-8 flex flex-col px-5 items-center gap-4">
                        {/* {
                            userData &&
                            userData.data.links.map((e) => {
                                if (e.socialmedia === 'WhatsApp' && userData.data.plan === 2) {
                                    return (
                                        <a
                                            href={`https://wa.me/${e.url_link}?text=${e.message}`}
                                            role='button'
                                            key={e.index + 'WhatsApp'}
                                            target="_blank"
                                            rel='noreferrer'
                                            style={{ backgroundColor: e.color }}
                                            className={`w-full text-white font-bold py-2 lg:py-4 px-4 rounded-md flex items-start justify-content-center items-center`}
                                        >
                                            <div className='h-10 w-10 flex items-center'>
                                                <img src={`${URL_BASE}/media/${e.image}`} alt="" />
                                            </div>
                                            <p className="text-center w-full text-lg lg:text-md font-medium">
                                                Hablame por WhatsApp
                                            </p>
                                        </a>
                                    );
                                } else if (e.socialmedia === 'Correo Electrónico' && userData.data.plan === 2) {
                                    return (
                                        <a
                                            href={`mailto:${userData.data.profile_email}?subject=${encodeURIComponent(e.url_link)}&body=${encodeURIComponent(e.message)}`}
                                            role='button'
                                            key={e.index + 'Email'}
                                            target="_blank"
                                            rel='noreferrer'
                                            style={{ backgroundColor: e.color }}
                                            className={`w-full text-white font-bold py-2 lg:py-4 px-4 rounded-md flex items-start justify-content-center items-center`}
                                        >
                                            <div className='h-10 w-10 flex items-center'>
                                                <img src={`${URL_BASE}/media/${e.image}`} alt="" />
                                            </div>
                                            <p className="text-center w-full text-lg lg:text-md font-medium">
                                                Hablame por correo
                                            </p>
                                        </a>
                                    );
                                } else {
                                    if (userData.plan === 1 && (e.socialmedia === "WhatsApp" || e.socialmedia === "Correo Electrónico")) {
                                        return null;
                                    } else {

                                        return (
                                            <a
                                                href={e.url_link.startsWith("http://") || e.url_link.startsWith("https://") ? e.url_link : `http://${e.url_link}`}
                                                role='button'
                                                key={e.index + 'RedesSociales'}
                                                target="_blank"
                                                rel="noreferrer"
                                                style={{ backgroundColor: e.color }}
                                                className={`w-full text-white font-bold py-2 lg:py-4 px-4 rounded-md flex relative items-start justify-content-center items-center`}
                                            >
                                                <div className='h-10 w-10 flex items-center'>
                                                    <img src={`${URL_BASE}/media/${e.image}`} alt="" />
                                                </div>
                                                <p className="text-center w-full text-lg lg:text-md font-medium absolute left-0">
                                                    {e.socialmedia}
                                                </p>
                                            </a>
                                        );
                                    }
                                }
                            })
                        } */}

                        {
                            userData &&
                            userData.data.links.map((e) => {
                                if (userData.data.plan === 1 && (e.socialmedia === 'WhatsApp' || e.socialmedia === 'Correo Electrónico')) {
                                    return null; // No renderizar enlaces de WhatsApp o Correo Electrónico si el plan es 1
                                } else {
                                    let linkComponent;
                                    if (e.socialmedia === 'WhatsApp') {
                                        linkComponent = (
                                            <a
                                                href={`https://wa.me/${e.url_link}?text=${e.message}`}
                                                role='button'
                                                key={e.index + 'WhatsApp'}
                                                target="_blank"
                                                rel='noreferrer'
                                                style={{ backgroundColor: e.color }}
                                                className={`w-full text-white font-bold py-2 lg:py-4 px-4 rounded-md flex items-start justify-content-center items-center`}
                                            >
                                                <div className='h-10 w-10 flex items-center'>
                                                    <img src={`${URL_BASE}/media/${e.image}`} alt="" />
                                                </div>
                                                <p className="text-center w-full text-lg lg:text-md font-medium">
                                                    Hablame por WhatsApp
                                                </p>
                                            </a>
                                        );
                                    } else if (e.socialmedia === 'Correo Electrónico') {
                                        linkComponent = (
                                            <a
                                                href={`mailto:${userData.data.profile_email}?subject=${encodeURIComponent(e.url_link)}&body=${encodeURIComponent(e.message)}`}
                                                role='button'
                                                key={e.index + 'Email'}
                                                target="_blank"
                                                rel='noreferrer'
                                                style={{ backgroundColor: e.color }}
                                                className={`w-full text-white font-bold py-2 lg:py-4 px-4 rounded-md flex items-start justify-content-center items-center`}
                                            >
                                                <div className='h-10 w-10 flex items-center'>
                                                    <img src={`${URL_BASE}/media/${e.image}`} alt="" />
                                                </div>
                                                <p className="text-center w-full text-lg lg:text-md font-medium">
                                                    Hablame por correo
                                                </p>
                                            </a>
                                        );
                                    } else {
                                        linkComponent = (
                                            <a
                                                href={e.url_link.startsWith('http://') || e.url_link.startsWith('https://') ? e.url_link : `http://${e.url_link}`}
                                                role='button'
                                                key={e.index + 'RedesSociales'}
                                                target="_blank"
                                                rel='noreferrer'
                                                style={{ backgroundColor: e.color }}
                                                className={`w-full text-white font-bold py-2 lg:py-4 px-4 rounded-md flex relative items-start justify-content-center items-center`}
                                            >
                                                <div className='h-10 w-10 flex items-center'>
                                                    <img src={`${URL_BASE}/media/${e.image}`} alt="" />
                                                </div>
                                                <p className="text-center w-full text-lg lg:text-md font-medium absolute left-0">
                                                    {e.socialmedia}
                                                </p>
                                            </a>
                                        );
                                    }
                                    return linkComponent;
                                }
                            })
                        }

                    </div>
                </div>
                {userData && userData.data.multimedia.length !== 0 && (
                    <div className="py-10 max-w-3xl mx-auto mt-8 flex flex-col px-5 items-center gap-4">
                        <h2 className="text-3xl font-bold">Productos y Servicios</h2>
                        <>
                            {userData.data.multimedia.map((e) => (
                                <div key={e.id} className="text-lg my-4">
                                    <p className='mb-4 font-semibold text-2xl break-words text-center'>{e.title}</p>
                                    <EmbeddedMediaSwitch link={e.link_media} windowWidth={windowWidth} />
                                </div>
                            ))}
                        </>
                    </div>
                )}

                <div className="py-5 max-w-3xl mx-auto mt-8 flex flex-col items-center gap-4">
                    <h2 className="text-3xl font-bold py-3 text-4xl">¡Comparte!</h2>
                    <div className="flex flex-col w-full lg:flex-row items-center justify-center mb-10">
                        <div className="bg-white rounded p-3 my-10 lg:my-0">
                            <QRCodeSVG value={window.location.href} style={{ width: '372px', height: 'auto' }} />
                        </div>

                        <div className="mx-auto w-full max-w-lg my-5  flex flex-col px-5 items-center gap-4">
                            {socialButtons.map((button, index) => (
                                !(userData && userData.data.plan === 1 && button.text.toLowerCase() === "whatsapp") && (
                                    <button
                                        key={index + 'Multimedias'}
                                        // className={`w-full text-white font-bold py-2 lg:py-4 px-4 rounded-md flex items-start justify-content-center items-center ${button.color}`}
                                        className={`w-full text-white font-bold py-2 lg:py-4 px-4 rounded-md flex relative items-start justify-content-center items-center ${button.color}`}

                                        onClick={() => {
                                            if (button.text.toLowerCase() === "facebook") {
                                                handleFacebookShare();
                                            } else if (button.text.toLowerCase() === "linkedin") {
                                                handleLinkedInShare();
                                            } else if (button.text.toLowerCase() === 'email') {
                                                handleEmailShare();
                                            } else if (button.text.toLowerCase() === "whatsapp") {
                                                handleWhatsAppShare();
                                            } else if (button.text.toLowerCase() === "agregar contacto") {
                                                createVCF(userData.data.profile_url);
                                            }
                                        }}
                                    >
                                        <FontAwesomeIcon
                                            icon={button.icon}
                                            className="fa-regular text-3xl text-white lg:text-3xl"
                                        />
                                        <p className="text-center w-full text-lg lg:text-md font-medium absolute left-0">
                                            {button.text}
                                        </p>
                                    </button>
                                )
                            ))}

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Preview

const EmbeddedMediaSwitch = ({ link, windowWidth }) => {
    const lowerCaseLink = link.toLowerCase().trim();

    const getEmbedComponent = () => {
        let width;
        if (windowWidth < 420) {
            width = 300;
        }
        else if (windowWidth <= 640) {
            width = 400;
            //     'md': '768px',
        } else if (windowWidth <= 768) {
            width = 500;
            //     'lg': '1024px',
        } else if (windowWidth <= 1024) {
            width = 600;
        } else if (windowWidth <= 1280) {
            width = 700;
        } else if (windowWidth <= 1536) {
            width = 800;
        } else if (windowWidth > 1536) {
            width = 1000;
        }
        //     'xl': '1280px',
        //     '2xl': '1536px',

        switch (true) {
            case lowerCaseLink.includes('youtube.com'):
            case lowerCaseLink.includes('youtu.be'):
                return <YouTubeEmbed url={link} width={width} height={width / 1.7} />
            case lowerCaseLink.includes('facebook.com'):
                return <FacebookEmbed url={link} width={width} />;
            case lowerCaseLink.includes('instagram.com'):
                return <InstagramEmbed url={link} width={width} />;
            case lowerCaseLink.includes('tiktok.com'):
                return <TikTokEmbed url={link} width={width} />;
            case lowerCaseLink.includes('pinterest.cl'):
            case lowerCaseLink.includes('pinterest.com'):
            case lowerCaseLink.includes('pinterest.es'):
                return <PinterestEmbed url={link} width={width} height={width} />;
            case lowerCaseLink.includes('linkedin.com'):
                return <LinkedInEmbed url={link} width={width} />;
            case lowerCaseLink.includes('twitter.com'):
                return <TwitterEmbed url={link} width={width} />;
            default:
                return <a href={link} className='p-4 bg-slate-200 block mt-5 rounded shadow '>{link}</a>;
        }
    };

    return (
        <div className="embedded-media">
            {getEmbedComponent()}
        </div>
    );
};


const handleFacebookShare = () => {
    const url = encodeURIComponent(window.location.href);
    const shareUrl = `https://www.facebook.com/sharer/sharer.php?u=${url}`;
    window.open(shareUrl, '_blank');
};

const handleLinkedInShare = () => {
    const url = encodeURIComponent(window.location.href);
    const shareUrl = `https://www.linkedin.com/sharing/share-offsite/?url=${url}`;
    window.open(shareUrl, '_blank');
};

const handleEmailShare = () => {
    const subject = encodeURIComponent('Asunto de correo');
    const body = encodeURIComponent('Contenido del correo electrónico.');
    const shareUrl = `mailto:?subject=${subject}&body=${body}`;
    window.location.href = shareUrl;
};

const handleWhatsAppShare = () => {
    const url = encodeURIComponent(window.location.href);
    const text = encodeURIComponent('¡Echa un vistazo a esta Tarjeta Digital!');
    const shareUrl = `https://api.whatsapp.com/send?text=${text}%20${url}`;
    window.open(shareUrl, '_blank');
};

const createVCF = async (id) => {
    try {
        window.open(`${URL_BASE_API}generate-vcf/${id}`);
    } catch (error) {
        console.error('Error al descargar el archivo VCF:', error);
        // Manejar el error, por ejemplo, mostrar un mensaje al usuario
    }
}