import { m, LazyMotion } from 'framer-motion';
import React, { useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { URL_BASE_API } from '../redux/DataContext';
import LottieAnimation from '../lottie/LottieAnimation';
import animationData from '../lottie/pass.json';
const Recuperar = () => {
  const emailRef = useRef();
  const navigate = useNavigate();

  const loadFeatures = () =>
    import("../features.js").then(res => res.default)

  const recuperar = (e) => {
    e.preventDefault()
    const email = emailRef.current.value;
    if (!email) { return }

    fetch(`${URL_BASE_API}password-change-request/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email }),
    }).then((response) => response.json())
      .then(data => {
        toast.success('Se ha enviado un correo con las instrucciones para recuperar la contraseña.');
      })
      .catch(error => {
        toast.error('Hubo un error al enviar el correo de recuperación.');
      });
  };

  

  return (
    <div className="bg-white sm:bg-gradient-to-r from-primary to-amber-500">
      <ToastContainer />
      <section className="min-h-screen relative">
        <div className="flex flex-col items-center justify-center  md:px-6 py-8 mx-auto h-screen lg:py-0">
          <div style={{ width: '15em' }} className=' absolute top-0'>
            <LottieAnimation animationData={animationData} loop={false} id={'recuperar'} />
          </div>
          <LazyMotion features={loadFeatures}>
            <m.div
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.6, delay: 0.3 }}
              className="w-full  sm:bg-white rounded-lg sm:shadow sm:border md:mt-0 sm:max-w-md xl:p-0"
            >
              <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
                <h1 className="text-2xl  text-center font-bold leading-tight tracking-tight text-gray-900 md:text-3xl text-slate-700">
                  Recuperar Contraseña
                </h1>
                <p className="text-md leading-tight tracking-tight text-gray-900 text-slate-700">
                  Te enviaremos un correo con las instrucciones.
                </p>
                <form className="space-y-4 md:space-y-6  border border-2 sm:border-0 p-5 sm:p-0 rounded" action="#">
                  <div className="relative">
                    <label htmlFor="email" className="block mb-5 text-xl font-medium text-slate-800">
                      Email
                    </label>
                    <input
                      ref={emailRef}
                      type="email"
                      name="email"
                      id="email"
                      required
                      className=" bg-slate-200 text-gray-900  rounded-lg block w-full p-2.5 placeholder-gray-400 text-slate-700 shadow-sm focus:outline-none"
                      placeholder='correo@email.com'
                    />
                  </div>
                  <button onClick={recuperar}
                    type="submit" className="w-full bg-primary hover:bg-primary-700 focus:ring-4 text-[19px] text-white focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-[17px] px-5 py-2.5 text-center ">Recuperar Contraseña</button>
                </form>
              </div>
            </m.div>
          </LazyMotion>
        </div>
      </section>
    </div>
  );
};

export default Recuperar;