import { useRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faCheck, faArrowRightLong } from '@fortawesome/free-solid-svg-icons';
import DotLoader from 'react-spinners/ClipLoader';
import { URL_BASE_API } from '../redux/DataContext';
import animationData from '../lottie/Crown2.json';
import LottieAnimation from '../lottie/LottieAnimation';
import { Modal } from './Modal';
import { toast } from 'react-toastify';

export const PremiumModal = ({
  isOpen,
  handleClose,
  backdropDismiss = true,
}) => {
  const cupon = useRef();
  const [loading, setLoading] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [isOpeningPopup, setIsOpeningPopup] = useState(false);

  const handleValidation = () => {
    if (loading) return;
    setLoading(true);
    const value = cupon.current.value;
    const url = `${URL_BASE_API}is-valid-coupon/${value}/`;

    if (value === '') {
      setIsValid(false);
      setLoading(false);
      return;
    }
    fetch(url, {
      method: 'GET',
    })
      .then((response) => response.json())
      .then((data) => {
        setIsValid(data.status);
      })
      .catch((error) => { })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleSubmit = () => {
    const codigo = cupon.current.value;

    let bodyCupon = {
      coupon: isValid ? codigo : '',
    };

    setIsOpeningPopup(true);

    fetch(`${URL_BASE_API}make-payment/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${localStorage.getItem('auth')}`,
      },
      body: JSON.stringify(bodyCupon),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.payment_link) {
          window.location.href = data.payment_link;
          // window.open(data.payment_link, '_blank');
        }
      })
      .catch((error) => {
        toast.error('Ha ocurrido un error.')
      })
      .finally(() => {
        setIsOpeningPopup(false);
      });
  };

  return (
    <Modal
      isOpen={isOpen}
      handleClose={handleClose}
      backdropDismiss={backdropDismiss}
      className={`relative m-5 p-5 bg-white rounded-lg shadow-lg'}`}
    >
      <div className='w-[8em] absolute -left-[4em] -top-[4em] -rotate-45'>
        <LottieAnimation animationData={animationData} loop={true} id={'Crown2'} />
      </div>
      <div className='px-5 pt-6 pb-3'>
        <h3 className='text-2xl pb-2 font-semibold'>
          <span className='text-amber-500'>¡Desbloquea Todo el </span>
          <span className='text-amber-600'>Potencial con la </span>
          <span className='text-amber-700'>Versión Premium!</span>
        </h3>
        <FeatureList />
        <div className='flex justify-end items-center gap-3'>
          <input
            type="text"
            ref={cupon}
            className='bg-slate-200 border border-1 border-slate-400 p-3 rounded-xl outline-none'
            placeholder="Introduce tu Cupón"
            inputMode="numeric"
            onInput={(e) => {
              e.target.value = e.target.value.replace(/\D/g, '');
            }}
          />
          <button
            onClick={handleValidation}
            className="text-white bg-gradient-to-br from-amber-500 to-orange-400 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-amber-500 rounded-lg text-sm px-5 py-3 text-center"
            disabled={loading}
          >
            {loading ? (
              <DotLoader
                color={'#000000'}
                loading={loading}
                size={20}
                aria-label="Loading Spinner"
                data-testid="loader"
              />
            ) : isValid ? (
              <div aria-label="Ticket válido" className='px-1 text-white font-bold' >
                <FontAwesomeIcon icon={faCheck} />
              </div>
            ) : (
              <div aria-label="Código no válido" className='px-1  text-white font-bold'>
                <FontAwesomeIcon icon={faArrowRightLong} />
              </div>
            )}
          </button>
        </div>
        <button
          onClick={handleSubmit}
          className="w-full text-white font-bold text-xl rounded-md hover-button flex justify-center items-center bg-amber-400 p-2 mt-6 shadow-md"
          disabled={isOpeningPopup}
        >
          {isOpeningPopup ? (
            <DotLoader
              color={'#ffffff'}
              loading={isOpeningPopup}
              size={30}
              aria-label="Loading Spinner"
              data-testid="loader"
            />
          ) : (
            'Obtener Premium'
          )}
        </button>
      </div>
    </Modal>
  );
};


const FeatureList = () => {
  return (
    <ul className='py-5 text-[18px] list-disc ml-5 flex flex-col gap-3'>
      <li className='flex items-center'>
        <FontAwesomeIcon icon={faCheckCircle} className='text-amber-500 mr-3' />
        Creación de Redes Sociales Ilimitadas
      </li>
      <li className='flex items-center'>
        <FontAwesomeIcon icon={faCheckCircle} className='text-amber-500 mr-3' />
        Añade tus productos y servicios. ¡Muestra lo que realizas!
      </li>
      <li className='flex items-center'>
        <FontAwesomeIcon icon={faCheckCircle} className='text-amber-500 mr-3' />
        Suscripción <span className='font-semibold mx-2'>Anual</span> de <span className='font-semibold ml-2'>$14.990 CLP</span>
      </li>
    </ul>
  );
}